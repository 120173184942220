<template>
    <div class="app-beautiful-products">
 <!-- <vs-select placeholder="Select" v-model="brand" :key="brands.length" @input="goSelect(brand)" filter>
        <vs-option :label="b.brand" :value="b.ID" v-for="(b, index) in brands" :key="index">
          {{b.brand}}
        </vs-option>
      </vs-select> -->
      <!-- <vs-button size="xl" @click="loadPage">Refresh</vs-button> -->
      <span style="display:block;margin-left:auto;text-align:center;margin-bottom:-30px;font-size:1.3rem;">{{done}}</span>
        <div>
            <div style="display:flex;">
                <vs-radio v-model="gender" :val="g.value" v-for="g in genders" :key="g.value">
                    {{g.name}}
                </vs-radio>
                
            </div>
            <div style="max-width:250px;margin-top:20px;">
                <vs-select placeholder="Select" v-model="category" @change="loadPage" :key="categories.length">
                    <vs-option :label="c.name" :value="c.value" v-for="(c, index) in categories" :key="index+222">
                        {{c.name}}
                    </vs-option>
                    
                </vs-select>
            </div>
        </div>
               <vs-button size="xl" @click="loadPage" style="margin-left:auto;margin-top:-80px;">Refresh</vs-button>
       <div class="app-beautiful-products__prod">
            <vs-card type="2" v-for="(p, index) in products" @click="sendProduct(p.ID, index)" :key="p.ID" style="margin-top:50px;"> 
            <template #title>
            <vs-button class="btn-chat" icon dark>
                <!-- <i class='bx bx-chat' ></i> -->
                <span class="span">
                 {{p.discount_price}} RON
                </span>
            </vs-button>
            <h3 style="height:50px;">
                <a target="_blank" :href="p.link" style="font-size:1.2rem;text-decoration:none;color:white">{{p.product_name}} 
                </a>npm
            </h3>

            
            </template>
            <template #img>
            <img :src="p.mainpicture" alt="">
            </template>
            <template #text>
            <p>
            </p>
            </template>
            <template #interactions>
            <!-- <vs-button danger icon v-if="p.level==0">
                <i class='bx bx-heart'></i>
            </vs-button>
            <vs-button danger icon v-if="p.level==0">
                <i class='bx bx-heart'></i>
            </vs-button> -->
            <vs-button dark icon v-if="p.level!=1" @click="changeLevel(index,1)">
                <i class='bx bx-like' ></i>1
            </vs-button>
            <vs-button danger icon v-if="p.level==1">
                <i class='bx bx-like' ></i>1
            </vs-button>
            <vs-button dark icon v-if="p.level!=2" @click="changeLevel(index,2)">
                <i class='bx bx-bookmark' ></i>2
            </vs-button>
            <vs-button danger icon v-if="p.level==2">
                <i class='bx bx-bookmark' ></i>2
            </vs-button>
            <vs-button dark icon v-if="p.level!=3" @click="changeLevel(index,3)">
                <i class='bx bx-dislike'></i>3
            </vs-button>
            <vs-button danger icon v-if="p.level==3">
                <i class='bx bx-dislike'></i>3
            </vs-button>
            <vs-button dark icon v-if="p.level!=4" @click="changeLevel(index,4)">
                <i class='bx bx-hide' ></i>4
            </vs-button>
            <vs-button danger icon v-if="p.level==4">
                <i class='bx bx-hide' ></i>4
            </vs-button>
            </template>
            
        </vs-card>
       </div>
       <vs-button size="xl" @click="loadPage" style="margin-left:auto;">Refresh</vs-button>
    </div>
</template>


<script>
export default {
    data() {
        return {
            products:[],
            marked:[],
            brand:"",
            brands:[],
            done:"",
            gender:1,
            genders:[],
            category:"",
            categories:[]
        }
    },
    methods:{
        sendProduct(id, index) {
            this.axios.get(`/markolxproduct?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&olxproduct=${id}`).then((response) => {
                console.log(response.data)
                this.marked[index] = true;
                this.products = [...this.products]
                console.log(this.marked)
          })  
        },
        async changeLevel(index, level){
            this.products[index].level = level

            await this.axios.get(`/levelproducts?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&markproductid=${this.products[index].ID}&marklevel=${level}`).then((response) => {
                 console.log(response.data)
            })
        },
        goSelect(id){
            window.location = "/selectolxproducts/"+id
        },
        loadPage(){
            const loading = this.$vs.loading()
            let id = "";
            if(this.$route.params.id)
            id = this.$route.params.id;
            console.log(id)
            // else  id = "";
            this.axios.get(`/levelproducts?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&select=${this.gender}&subselect=${this.category}`).then((response) => {
                 console.log(response.data)
                 this.done = response.data.done;
                 this.products = []
                 this.categories = [...response.data.subselect]
                if(response.data.levelproducts!=null)
                    this.products = [...response.data.levelproducts]
                this.marked=[...this.products];
                this.brands = response.data.selectbrand;
               this.genders = response.data.select;
                if(this.$route.params.id) this.brand = [this.$route.params.id];
                console.log(this.brand)
                loading.close()
            })
        }
    },
    watch:{
        gender(){
            this.category = "";
            this.loadPage();
        }
    },
    beforeMount() {
        this.loadPage()
    }
}
</script>


<style lang="scss" scoped> 
.app-beautiful-products { 
    padding: 2rem;;

    &__prod {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

</style>