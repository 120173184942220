<template>
    <div class="app-g-ads">
    <div class="app-g-ads__title">
        <span >
            <!-- <span class="app-g-ads__title-txt" :class="{'app-g-ads__title-txt-active':nameActive}" @click="copyText2(name, 'name')">{{name}}</span> -  -->
            <span class="app-g-ads__title-txt" :class="{'app-g-ads__title-txt-active':linkActive}" @click="copyText2(link, 'link')">{{link}}</span>
        </span>
    </div>
    <div style="margin-top:1rem;display:flex;">
        <vs-button
            style="margin-left:auto;"
           @click="generateAdd"
        >
        Generate
      </vs-button>
    </div>
     <vs-table style="margin-top:1rem;">
        <template #thead>
          <vs-tr>
            <vs-th>
             Head line
            </vs-th>
            <vs-th>
              Long Line
            </vs-th>
            <vs-th>
              Description
            </vs-th>
            <vs-th>
              Business name
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in table"
            :data="tr"
          >
            <vs-td v-if="tr.headline">
                <span  class="copy-template" @click="copyText(tr.headline, i, 'h_active')" :class="{'copy-template-active':tr.h_active}">{{tr.headline}}</span>
            </vs-td>
            <vs-td v-if="tr.longline">
                <span  class="copy-template" @click="copyText(tr.longline, i, 'l_active')" :class="{'copy-template-active':tr.l_active}">{{tr.longline}}</span>
            </vs-td>
            <vs-td v-if="tr.description">
                <span  class="copy-template" @click="copyText(tr.description, i, 'd_active')" :class="{'copy-template-active':tr.d_active}">{{tr.description}}</span>
            </vs-td>
            <vs-td v-if="tr.businessname">
                <span  class="copy-template" @click="copyText(tr.businessname, i, 'b_active')" :class="{'copy-template-active':tr.b_active}">{{tr.businessname}}</span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <img style="display:block;width:200px;margin-left:auto;margin-right:10px;cursor:pointer;" 
      v-if="list[2]" @click="downloadImage(list[2].content)" :src="list[2].content" alt="">
      <!-- <button @click="generateZIP">Download images</button> -->
    </div>
</template>


<script>
import JSZip from "jszip"
import { saveAs } from 'file-saver';
import JSZipUtils from "jszip-utils"
export default {
    data(){
        return {
            list:[],
            table:[],
            name:"", 
            link:"",
            nameActive:false,
            linkActive:false,
             links:["https://dmgroup.ro/api/uploads/678183/big/b8c2a35d132196d7c8a40a9f786a95ca.jpg","https://dmgroup.ro/api/uploads/691440/big/3ae10f1ec84140ac0f05ca2ed78ec85a.jpg"]
        }
    },
    methods:{
        async downloadImage(imageSrc) {
            const image = await fetch(imageSrc)
            const imageBlog = await image.blob()
            const imageURL = URL.createObjectURL(imageBlog)

            const link = document.createElement('a')
            link.href = imageURL
            link.download = 'image'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            },
        generateAdd() {
            this.nameActive = false;
            this.linkActive = false;
            this.table = []
            this.axios.get(`/generatetrafficdisplay?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&generatead=1`).then((response) => {
                this.list = response.data.list;
                this.name = response.data.list[0].content;
                this.link = response.data.list[1].content;
                console.log(this.list)
                for(let i = 3; i < this.list.length;i++){
                    this.table.push(this.list[i]);
                }
                let cpy = [];
                for(let i = 0; i < 10;i++) {
                    let template = {
                        headline:this.table[0].content[i],
                        longline:this.table[1].content[i],
                        description:this.table[2].content[i],
                        businessname:this.table[3].content[i],
                        h_active:false,
                        l_active:false,
                        d_active:false,
                        b_active:false
                    };
                    cpy.push(template);
                }
                console.log(cpy)
                console.log(this.table)
                this.table = [...cpy]
          })
        },
        copyText(text, index, t) {
            this.copyText2(text)
            if(t == "b_active" ) {
                this.table[index].b_active=true;
                console.log(text)
            }
            if(t == "h_active" ) {
                this.table[index].h_active=true;
                console.log(text)
            }
            if(t == "l_active" ) {
                this.table[index].l_active=true;
                console.log(text)
            }
            if(t == "d_active" ) {
                this.table[index].d_active=true;
                console.log(text)
            }
            if(t == "long" ) {
                this.list[index].longActive=true;
                console.log(text)
            }
        },
        copyText2(text, t){
            if(t=='name') this.nameActive = true;
            if(t=='link') this.linkActive = true;
            var input = document.createElement('input');
            input.setAttribute('value', text);
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand('copy');
            document.body.removeChild(input);
            return result;
        },
generateZIP() {
        console.log('TEST');
        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Pictures.zip";
    let links = [...this.links]
    links.forEach(function (url, i) {
        var filename = links[i];
        filename = i;
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
            throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count == links.length) {
            zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename);
            });
        }
        });
    });
    }
    }
}
</script>


<style lang="scss" scoped>

.app-g-ads {
    &__title {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 2rem;
        transform: translateY(2.5rem);

        span {
             cursor: pointer;
        }

        &-txt {
            &-active {
                text-decoration: line-through;
            }
        }
    }
}
.copy-template {
    cursor: pointer;
    padding: 1rem;
    box-sizing: border-box;
    display: block;
    background: rgb(36, 33, 69);
    border-radius: 10px;
    color: white;

    &-active {
        background: rgb(23, 201, 100);
        border-radius: 10px;
        color: white;
    }
}
</style>
