<template>
    <div class="app-beautiful-products">
        <vs-select placeholder="Select" v-model="brand" :key="brands.length" @input="goSelect(brand)" filter>
        <vs-option :label="b.brand" :value="b.ID" v-for="(b, index) in brands" :key="index">
          {{b.brand}}
        </vs-option>
      </vs-select>
      <!-- <vs-button size="xl" @click="loadPage">Refresh</vs-button> -->
       <div class="app-beautiful-products__prod">
            <vs-card type="2" v-for="(p, index) in products" @click="sendProduct(p.ID, index)" :key="p.ID" style="margin-top:50px;"> 
            <template #title>
            <h3>{{p.product_name}}</h3>
            </template>
            <template #img>
            <img :src="p.mainpicture" alt="">
            </template>
            <template #text>
            <p>
            </p>
            </template>
            <template #interactions>
            <vs-button class="btn-chat" icon dark>
                <!-- <i class='bx bx-chat' ></i> -->
                <span class="span">
                 {{p.discount_price}} RON
                </span>
            </vs-button>
            <vs-button danger icon v-if="marked[index]==true">
                <i class='bx bx-heart'></i>
            </vs-button>
            <vs-button dark icon v-else>
                <i class='bx bx-heart'></i>
            </vs-button>
            </template>
        </vs-card>
       </div>
       <vs-button size="xl" @click="loadPage" style="margin-left:auto;">Refresh</vs-button>
    </div>
</template>


<script>
export default {
    data() {
        return {
            products:[],
            marked:[],
            brand:"",
            brands:[]
        }
    },
    methods:{
        sendProduct(id, index) {
            this.axios.get(`/markbeautifulproduct?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&beautifulproductid=${id}`).then((response) => {
                console.log(response.data)
                this.marked[index] = true;
                this.products = [...this.products]
                console.log(this.marked)
          })  
        },
        goSelect(id){
            window.location = "/selectbeautifulproducts/"+id
        },
        loadPage(){
            const loading = this.$vs.loading()
            let id = "";
            if(this.$route.params.id)
            id = this.$route.params.id;
            console.log(id)
            // else  id = "";
            this.axios.get(`/selectbeautifulproducts?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&brandid=${id}`).then((response) => {
                 console.log(response.data)
                 this.products = []
                if(response.data.beautifulproducts!=null)
                    this.products = [...response.data.beautifulproducts]
                this.marked=[...this.products];
                this.brands = response.data.selectbrand;
               
                if(this.$route.params.id) this.brand = [this.$route.params.id];
                console.log(this.brand)
                loading.close()
            })
        }
    },
    beforeMount() {
        this.loadPage()
    }
}
</script>


<style lang="scss" scoped>
.app-beautiful-products {
    padding: 2rem;;

    &__prod {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
</style>