<template>
    <div class="app-homepage">
<template>
    <div class="center">
      <vs-dialog not-close prevent-close  v-model="active">
        <template #header>
          <h4 class="not-margin">
            Welcome
          </h4>
        </template>


        <div class="con-form">
          <vs-input v-on:keyup.13="loginUser" v-model="login" color="#de5038" placeholder="Username" style="padding-bottom:1rem;">
            <template #icon>
              @
            </template>
            <template slot="message-primary" v-if="errors.login!=true">
              {{errors.login}}
            </template> 
          </vs-input>
          <vs-input 
          v-on:keyup.13="loginUser"
          type="password" 
          color="#de5038" v-model="password" 
          placeholder="Password" 
          style="padding-bottom:1rem;">
            <template slot="message-primary" v-if="errors.password!=true">
              {{errors.password}}
            </template>
            <template #icon>
              <i class='bx bxs-lock'></i>
            </template>
            
          </vs-input>
          <!-- <div class="flex">
            <vs-checkbox v-model="checkbox1">Remember me</vs-checkbox>
          </div> -->
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button block @click="loginUser">
              Sign In
            </vs-button>
          </div>
        </template>
      </vs-dialog>
    </div>
  </template>
    </div>
</template>

  <script>
    export default {
      data:() => ({
        active: true,
        login: '',
        password: '',
        checkbox1: false,
        errors:{
          login:true,
          password:true
        }
      }),
      methods:{
        loginUser(){
          this.axios.get(`/login?login=${this.login}&password=${this.password}`).then((response) => {
            response = response.data;
            this.errors.login = response.login.validuser;
            this.errors.password = response.login.validpassword;
            if(response.login.loginsuccess==true){
              this.$cookies.set("token",response.login.admintoken);
              this.$cookies.set("admin_id", response.login.adminid);
              window.location = "/comenzi"
            }
            console.log(response.login)
          })
        }
      },
      beforeMount(){
        const loading = this.$vs.loading()
        this.axios.get(`/login?login=${this.login}&password=${this.password}&adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
            response = response.data;
            console.log(response)
            if(response.validsession) window.location = "/comenzi"
            loading.close()
          })
        }
      }
  </script>

<style lang="scss" scoped>
.app-homepage {  
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
  .app-homepage {  
    width: 100vw;
    height: 100vh;
    background: url("../assets/images/background_small.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

</style>