<template>
    <div class="debugalex" v-if="list.length!=0" >
        <span style="display:block;padding:1rem;" v-for="l in list" :key="l.value">{{l}}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list:[]
        }
    },
    beforeMount(){
        this.axios.get(`/debugalex?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
            response = response.data;
            this.list = response.values;
        })
    }
}
</script>