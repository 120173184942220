<template>
    <div class="app-title">
        <span>{{title}}</span>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String
        }
    }
}
</script>


<style lang="scss" scoped>
.app-title {

    span {
        font-size: 1.5rem;
        font-family: $medium-main;
        padding-left: 10px;
    }
}
</style>