<template>
    <div class="work-logs">
        <vs-table stripped>
        <template #thead>
          <vs-tr style="color:black;">
            <vs-th>
              ID
            </vs-th>
            <vs-th>
              Our_Brand
            </vs-th>
            <vs-th>
              Olx_Brand
            </vs-th>
            <vs-th>
              Action
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in logs"
            :data="tr"
            :style="tr.color" 
        class="work-logs__log"
          >
            <vs-td style="padding:1rem;">
              {{tr.ID}}
            </vs-td>
            <vs-td>
             {{tr.Our_Brand}}
            </vs-td>
            <vs-td>
            <vs-input
                style="padding:1rem 0;"
                label-placeholder="Olx Brand"
                v-model="tr.Olx_Brand" 
            />
            </vs-td>
            <vs-td>
                <vs-button @click="sendBrand(tr.ID, tr.Olx_Brand)">
                    Send
                </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
</template>


<script>
export default {
    data(){
        return {
            logs:[],
            active:true,
        }
    },
    methods:{
        goOrder(){
            window.location = "/istoricorder/" + this.orderid;
        },
        closeModal(){
            //  window.history.back();
            window.location = "/main"
        },
        sendBrand(ID, olxbrandname){
            this.axios.get(`/syncbrandolx?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&ID=${ID}&olxbrandname=${olxbrandname}`).then((response) => {
                response = response.data;
                console.log(response) 
                this.logs = response.table;
                this.$store.commit("VALIDATE_TOKEN",response.validsession)
                this.$vs.notification({
                        progress: 'auto',
                        color:"success",
                        position:"top-right",
                        title: 'Ai actualizat numele brand-ului cu succes 😄',
                    })
             })
        }
    },
    beforeMount() {
        const loading = this.$vs.loading()
        this.axios.get(`/syncbrandolx?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${this.$route.params.id}`).then((response) => {
            response = response.data;
            console.log(response) 
            this.logs = response.table;
            this.$store.commit("VALIDATE_TOKEN",response.validsession)
            loading.close()
        })
    }
}
</script>

<style lang="scss" scoped>
.work-logs {
    padding: 1rem;
    color: black;
    font-family: $semibold-main;

    &__log {
        cursor: pointer;

        &:hover {
            color: black;
        }
    }
}
</style>