<template>
    <div class="general-info" style="padding:20px;">
        <div style="width:300px;">
                     <vs-select placeholder="Select" v-model="value" :key="list.length"> 
        <vs-option :label="el.name" :value="el.value" v-for="(el, index) in list" :key="index">
          {{el.name}}
        </vs-option>
      </vs-select>
        </div>
         <vs-table style="margin-top:20px;">
    <template #thead>
      <vs-tr>
        <vs-th>
          
        </vs-th>
        <vs-th>
          
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i"
        v-for="(tr, i) in list2"
        :data="tr"
      > 
        <vs-td>
          {{ tr.name }}
        </vs-td> 
        <vs-td>
          {{ tr.value }} 
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
    </div>
</template>


<script>
export default {
    data() {
        return {
            list:[],
            value:0,
            list2:[]
        }
    },
    methods:{
        loadPage(){
            this.axios.get(`/generalinfo?&adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&value=${this.value}`).then((response) => {
                response = response.data;
                console.log("🚀 ~ file: GeneralInfo.vue ~ line 42 ~ this.axios.get ~ response", response)
                this.list = response.select;
                this.value = response.selectedvalue;
                this.list2 = response.list;
            })
        }
    },
    beforeMount() {
        this.loadPage()
    },
    watch:{
        value(){
            this.loadPage()
        }
    }
}
</script>