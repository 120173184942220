<template>
    <div class="app-g-ads">
    <div class="app-g-ads__title">
        <span >
            <span class="app-g-ads__title-txt" :class="{'app-g-ads__title-txt-active':nameActive}" @click="copyText2(name, 'name')">{{name}}</span> - 
            <span class="app-g-ads__title-txt" :class="{'app-g-ads__title-txt-active':linkActive}" @click="copyText2(link, 'link')">{{link}}</span>
        </span>
    </div>
    <div style="margin-top:1rem;display:flex;">
        <vs-button
            style="margin-left:auto;"
           @click="generateAdd"
        >
        Generate
      </vs-button>
    </div>
     <vs-table style="margin-top:1rem;">
        <template #thead>
          <vs-tr>
            <vs-th>
              Short Titles
            </vs-th>
            <vs-th>
              Long Titles
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in list"
            :data="tr"
          >
            <vs-td >
                <span  class="copy-template" @click="copyText(tr.short, i, 'short')" :class="{'copy-template-active':tr.shortActive}">{{tr.short}}</span>
            </vs-td>
            <vs-td >
               <span v-if="tr.long" class="copy-template" @click="copyText(tr.long, i, 'long')" :class="{'copy-template-active':tr.longActive}">{{tr.long }}</span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
</template>


<script>
export default {
    data(){
        return {
            list:[],
            name:"", 
            link:"",
            nameActive:false,
            linkActive:false
        }
    },
    methods:{
        generateAdd() {
            this.nameActive = false;
            this.linkActive = false;
            this.axios.get(`/generategooglesearchads?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&generatead=1`).then((response) => {
                this.list = response.data.list;
                console.log(this.list)
                this.name = this.list[0].content
                this.link = this.list[1].content
                let newList = [];
                for(let i = 0; i < response.data.list[2].content.length;i++){
                    let template = {
                        short:response.data.list[2].content[i],
                        long:response.data.list[3].content[i],
                        shortActive:false,
                        longActive:false
                    }
                    newList.push(template)
                    // newList[i].short = response.data.list[1].content[i];
                    // if(response.data.list[2].content[i]) newList[i].long = response.data.list[2].content[i];else newList[i].long = "";
                }
                // console.log(response.data.list[1].content)
                console.log(newList)
                this.list = [...newList];
                // console.log(this.list)
          })
        },
        copyText(text, index, t) {
            this.copyText2(text)
            if(t == "short" ) {
                this.list[index].shortActive=true;
                console.log(text)
            }
            if(t == "long" ) {
                this.list[index].longActive=true;
                console.log(text)
            }
        },
        copyText2(text, t){
            if(t=='name') this.nameActive = true;
            if(t=='link') this.linkActive = true;
            var input = document.createElement('input');
            input.setAttribute('value', text);
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand('copy');
            document.body.removeChild(input);
            return result;
        }
    }
}
</script>


<style lang="scss" scoped>

.app-g-ads {
    &__title {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 2rem;
        transform: translateY(2.5rem);

        span {
             cursor: pointer;
        }

        &-txt {
            &-active {
                text-decoration: line-through;
            }
        }
    }
}
.copy-template {
    cursor: pointer;
    padding: 1rem;
    box-sizing: border-box;
    display: block;
    background: rgb(36, 33, 69);
    border-radius: 10px;
    color: white;

    &-active {
        background: rgb(23, 201, 100);
        border-radius: 10px;
        color: white;
    }
}
</style>