<template>
    <div class="app-table">
        <vs-table striped>
    <template #thead>
      <vs-tr>
        <vs-th v-for="(x, index) in info.table" :key="index">
          {{x}}
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i"
        v-for="(tr, i) in info.content"
        :data="tr"
      >
        <vs-td v-for="(td,i) in tr" :key="i">
              <vs-button icon v-if="i==0">{{td.value}} </vs-button>
              <p v-else>{{td.value}}</p>
        </vs-td>
        
      </vs-tr>
    </template>
  </vs-table> 
    </div>
</template>

<script>
export default {
    props:{
        info:{
            type:Array
        }
    }
}
</script>