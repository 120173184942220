<template>
    <div class="campaigns">
    <vs-button
        style="margin-left:auto;"
        gradient
        @click="dialog=true"
    >
        Adauga campanie
    </vs-button>
        <vs-table>
    <template #thead>
      <vs-tr>
        <vs-th>
          ID
        </vs-th>
        <vs-th>
          Campaign
        </vs-th>
        <vs-th>
          Type
        </vs-th>
        <vs-th>
          Observations
        </vs-th>
        <vs-th>
          Created Time
        </vs-th>
        <vs-th>
          Register Users
        </vs-th>
        <vs-th>
          Total Orders
        </vs-th>
        <vs-th>
          Total value
        </vs-th>
        <vs-th>
          Clicks
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i"
        v-for="(tr, i) in list"
        :data="tr"
        style="cursor:pointer;"
        @click="goLink('/singlecampain/'+ tr.ID )"
      >
        <vs-td>
          {{ tr.ID }}
        </vs-td>
        <vs-td>
         {{ tr.campain }}
        </vs-td>
        <vs-td>
         {{ tr.type }}
        </vs-td>
        <vs-td>
         {{ tr.observations }}
        </vs-td>
        <vs-td>
         {{ tr.created_time }}
        </vs-td>
        <vs-td>
         {{ tr.register_users }}
        </vs-td>
        <vs-td>
         {{ tr.total_orders }}
        </vs-td>
        <vs-td>
         {{ tr.total_value }}
        </vs-td>
        <vs-td>
         {{ tr.clicks }}
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
<vs-dialog v-model="dialog">
        <template #header>
          <h4 class="not-margin">
            Adauga campanie
          </h4>
        </template>
        <vs-input
            primary
            v-model="campaign.name"
            label-placeholder="Nume campanie" />
        <vs-select placeholder="Tip campanie" v-model="campaign.t" :key="types.length" style="margin:1.5rem 0;"> 
        <vs-option :label="t" :value="t" v-for="(t, index) in types" :key="index">
          {{t}}
        </vs-option>
      </vs-select >
        <vs-input
            primary
            v-model="campaign.obs"
            label-placeholder="Observatii" />
        <vs-button
            @click="addCampaign"
            style="margin:0 auto;margin-top:1rem;"
        >
            Adauga
        </vs-button>
      </vs-dialog>
    </div>
</template>


<script>
export default {
    data(){
        return {
            list:[],
            dialog:false,
            types:[],
            campaign:{
                name:"",
                t:"",
                obs:""
            }
        }
    },
    methods:{
        goLink(link){
            this.$router.push(link)
        },
        addCampaign() {
            if(!this.campaign.name || !this.campaign.t) {
                this.$vs.notification({
                    color:'danger',
                    position:"top-right",
                    title: 'Eroare',
                    text: 'Numele si tipul campaniei sunt obligatorii!'
                })
            }
            else 
                this.axios.get(`/campains?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&campainname=${this.campaign.name}&campaintype=${this.campaign.t}&campainobservations=${this.campaign.obs}`).then((response) => {
                    response = response.data;
                    this.campaign.t = "";
                    this.campaign.name =  "";
                    this.campaign.obs = "";
                    console.log(response)
                    this.dialog = false;
                    this.loadPage();
                })  
        },
        loadPage(){
            this.axios.get(`/campains?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
                response = response.data;
                if(Array.isArray(response.list))
                    this.list = [...response.list];
                this.types = [...response.types];
                console.log(response)
            })
        }
    },
    beforeMount(){
        this.loadPage()
    }
}
</script>