<template>
    <div class="app-add-product">
        <h4>Adauga produs</h4>
        <div class="cnt-prod" v-if="genders">
            <span
                style="
                    display: block;
                    margin-left: 10px;
                    font-size: 12px;
                    padding-bottom: 5px;
                "
                >Gen</span
            >
            <vs-select
                id="gender"
                placeholder="Gen"
                v-model="gender"
                :key="genders.length"
            >
                <vs-option
                    :label="g.name"
                    :value="g.valuemaincategory"
                    v-for="(g, index) in genders"
                    :key="index"
                >
                    {{ g.name }}
                </vs-option>
            </vs-select>
        </div>
        <div class="cnt-prod" v-if="selectsubcategory">
            <span
                style="
                    display: block;
                    margin-left: 10px;
                    font-size: 12px;
                    padding-bottom: 5px;
                "
                >Subcategorie</span
            >
            <vs-select
                placeholder="Subcategorie"
                v-model="subcategory"
                :key="selectsubcategory.length"
            >
                <vs-option
                    :label="g.name"
                    :value="g.valuesubcategory"
                    v-for="(g, index) in selectsubcategory"
                    :key="index"
                >
                    {{ g.name }}
                </vs-option>
            </vs-select>
        </div>
        <div class="cnt-prod" v-if="smallcategory&&smallcategory.length">
            <span
                style="
                    display: block;
                    margin-left: 10px;
                    font-size: 12px;
                    padding-bottom: 5px;
                "
                >Subcategorie</span
            >
            <vs-select
                placeholder="Subcategorie"
                v-model="smallcategoryvalue"
                :key="smallcategory.length"
            >
                <vs-option
                    :label="g.name"
                    :value="g.valuesmallcategory"
                    v-for="(g, index) in smallcategory"
                    :key="index"
                >
                    {{ g.name }}
                </vs-option>
            </vs-select>
        </div>
        <!-- <div class="cnt-prod">
            <vs-input v-model="numeProdus" placeholder="Nume produs" />
        </div> -->
        <div v-for="(d, index) in debug" :key="index+948">
            <span>{{d.name}} - {{d.value}}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            numeProdus: "",
            gender: "",
            genders: null,
            selectsubcategory: null,
            subcategory:"",
            smallcategory:null,
            smallcategoryvalue:"",
            debug:""
        };
    },
    methods: {
        sendData() {
            this.axios
                .get(
                    `/adaugaremanualaprodus?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&valuemaincategory=${this.gender}&valuesubcategory=${this.subcategory}&valuesmallcategory=${this.smallcategoryvalue}` 
                )
                .then((response) => {
                    response = response.data;
                    this.selectsubcategory = response.selectsubcategory;
                    this.smallcategory = response.smallcategory;
                    this.debug = response.debug;
                    console.log(
                        "🚀 ~ file: AddProduct.vue ~ line 37 ~ .then ~ response",
                        response
                    );
                });
        },
        loadPage() {
            this.axios
                .get(
                    `/adaugaremanualaprodus?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`
                )
                .then((response) => {
                    response = response.data;
                    this.genders = response.selectmaincategory;
                    console.log(
                        "🚀 ~ file: AddProduct.vue ~ line 37 ~ .then ~ response",
                        response
                    );
                });
        },
    },
    beforeMount() {
        this.loadPage();
    },
    watch: {
        gender() {
            this.sendData();
        },
        subcategory() {
            this.sendData();
        },
        smallcategoryvalue() {
            this.sendData();
        }
    },
};
</script>

<style lang="scss" scoped>
.app-add-product {
    padding: 20px;
}

.cnt-prod {
    margin: 20px 0;
    box-sizing: border-box;
    width: 90vw;
}
</style>
