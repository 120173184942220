<template>
    <div class="app-list-users">
         <vs-table>
    <template #thead>
      <vs-tr>
        <vs-th>
          ID
        </vs-th>
        <vs-th>
          Name
        </vs-th>
        <vs-th>
          Sex
        </vs-th>
        <vs-th>
          Email
        </vs-th>
        <vs-th>
          email_confirmed
        </vs-th>
        <vs-th>
          Inregistrat la data
        </vs-th>
        <vs-th>
          Telefon
        </vs-th>
        <vs-th>
          Last login
        </vs-th>
        <vs-th>
          IP
        </vs-th>
        <vs-th>
          Tara
        </vs-th>
        <vs-th>
          Oras
        </vs-th>
        <vs-th>
          Comenzi
        </vs-th>
        <vs-th>
          Action
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i"
        v-for="(tr, i) in users"
        :data="tr"
      >
        <vs-td>
          {{ tr.user }}
        </vs-td>
        <vs-td>
          {{ tr.name }}
        </vs-td>
        <vs-td>
         {{ tr.sex }}
        </vs-td>
        <vs-td>
         {{ tr.email }}
        </vs-td>
        <vs-td>
         {{ tr.email_confirmed }}
        </vs-td>
                <vs-td>
         {{ tr.registration }}
        </vs-td>
        <vs-td>
         {{ tr.phone }}
        </vs-td>
            <vs-td>
         {{ tr.lastlogin }}
        </vs-td>
            <vs-td>
         {{ tr.ip }}
        </vs-td>
                    <vs-td>
         {{ tr.country }}
        </vs-td>
            <vs-td>
         {{ tr.city }}
        </vs-td>
            <vs-td>
         {{ tr.orders }}
        </vs-td>
        <vs-td>
        <vs-button  
        v-if="tr.delete.show" 
        color="danger" 
        @click="deleteUser(tr.delete.deleteuseridvalue)"
      >
        Delete
      </vs-button>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            users:[]
        }
    },
    methods:{
        deleteUser(id){
            this.axios.get(`/listusers?&adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&deleteuseridvalue=${id}`).then(() => {
                this.loadPage()
            })
        },
        loadPage(){
            this.axios.get(`/listusers?&adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
                response = response.data;
                this.users = response.users;
            })
        }
    },
    beforeMount() {
        this.loadPage()
    }
}
</script>



<style lang="scss" scoped>
.app-list-users {
    padding:20px;
}
</style>