<template>
    <div class="work-logs" >
        <vs-dialog not-close v-model="active" v-if="!$route.params.id" @close="closeModal">
        <template #header>
          <h4 class="not-margin">
            Please enter order User ID
          </h4>
        </template>
    <vs-input border primary type="email" icon-after v-model="orderid" label-placeholder="ID">
        <template #icon>
          <i class='bx bxl-bitcoin'></i>
        </template>
      </vs-input>
            <vs-button
            style="margin:0 auto;margin-top:1.5rem;"
                gradient
                :active="true"
                @click="goOrder"
                >
            Search
      </vs-button>
      </vs-dialog>
        <vs-table stripped>
        <template #thead>
          <vs-tr style="color:black;">
            <vs-th>
              ActionNr
            </vs-th>
            <vs-th>
              Page
            </vs-th>
            <vs-th>
              Time
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in logs"
            :data="tr"
            :style="tr.color" 
            class="work-logs__log"
          >
            <vs-td style="padding:1rem;">
              {{tr.ActionNr}}
            </vs-td>
            <vs-td>
             {{tr.Page}}
            </vs-td>
            <vs-td>
             {{tr.Time}}
            </vs-td>
            <vs-td>
             {{tr.Pret_Achizitie}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
</template>


<script>
export default {
    data(){
        return {
            logs:[],
            active:true,
            orderid:""
        }
    },
    methods:{
        goOrder(){
            window.location = "/istoricactionuser/" + this.orderid;
        },
        closeModal(){
            //  window.history.back();
            window.location = "/main"
        }
    },
    beforeMount() {
        this.axios.get(`/istoricactionuser?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&userid=${this.$route.params.id}`).then((response) => {
            response = response.data;
            console.log(response) 
            this.logs = response.table;
            this.$store.commit("VALIDATE_TOKEN",response.validsession)
        })
    }
}
</script>

<style lang="scss" scoped>
.work-logs {
    padding: 1rem;
    color: white;
    font-family: $semibold-main;

    &__log {
        cursor: pointer;

        &:hover {
            color: black;
        }
    }
}
</style>