<template>
    <div class="contabilitate">
        <div style="display:flex;flex-wrap:wrap;">
         <div class="center" style="max-width:300px;">
      <vs-select placeholder="Select" v-model="value" style="margin-top:20px;" @change="loadPage" :key="select_data.length">
        <vs-option :label="d.name" :value="d.type_invoice" v-for="(d, index) in select_data" :key="index+12343">
          {{d.name}}
        </vs-option>
      </vs-select>
    </div>
    <div style="width:400px;margin-top:20px;margin-left:30px;">
        <VueCtkDateTimePicker :only-date="true" format="DD-MM-YYYY" :range="true" hint="Start date" v-model="time" @input="loadPage" ></VueCtkDateTimePicker> 
     
        <!-- <VueCtkDateTimePicker :only-date="true" v-model="time"  />    -->
    </div>
</div>
    <div style="margin-top:50px;">
     <vs-table>
    <template #thead>
      <vs-tr>
        <vs-th style="width:600px;">
          
        </vs-th>
        <vs-th>
          
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        v-for="(i, index) in info"
        :key="index"
      >
        <vs-td style="width:300px;">
         {{i.name}}
        </vs-td>
        <vs-td >
         {{i.value}}
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
  </div>
     <a v-if="show_download" :href="link" style="display:block;text-decoration:none;margin-left:auto; ">
            <vs-button
        gradient
      >
        Descarca
      </vs-button>
     </a>
    </div>
</template>

<script>

export default {
    data() {
        return {
            value:"",
            time:"",
            select_data:[],
            info:[],
            link:"",
            show_download:true
        }
    },
    methods:{
                loadPage() {
                    // alert(this.time.start)
                    // alert(this.time.end)
            this.axios.get(`/contabilitate?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&idcomanda=${this.$route.params.id}&type_invoice=${this.value}&startdate=${this.time.start}&enddate=${this.time.end}`).then((response) => {
                response = response.data;
                this.select_data = response.select;
                this.info = response.info;
                this.link = response.downlaodlink;
                this.value = response.defaultactive;
                this.show_download = response.showdownloadlink;
                console.log(response)
            })
        },
        openLink() {
            
        }
    },
    beforeMount() {
        this.loadPage();
    }
}
</script>


<style lang="scss" scoped>
.contabilitate {
    padding: 10px;;
    min-height: 100vh;
}
</style>