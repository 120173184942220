<template>
  <div id="app">
    <vue-headful
        title="DM Group Panel"
        description="Description from vue-headful"
    />
    <div v-if="$cookies.get('token')&&$cookies.get('admin_id')">
      <AppSidebar/>
      <div class="logged-cnt" id="logged-cnt">
        <router-view/> 
      </div>
    </div>
    <div v-else>
      <router-view/>
    </div>
  </div>
</template>

<script>
import AppSidebar from "./components/AppSidebar.vue"
import $ from 'jquery'
export default {
  components:{
    AppSidebar
  },
  beforeMount(){
    if(this.$cookies.get("token")&&this.$cookies.get("admin_id"))
      this.$store.dispatch("sidebarGet")
  },
  mounted(){
        if(window.innerWidth<1600 && window.innerWidth > 950&&this.$route.name=='AppLevelProducts') {
            document.body.classList.add("zoom-browser");
            $('#logged-cnt').addClass('level-prod')
        }
  },
  watch:{
    '$route.name':function(){
        if(window.innerWidth<1600 && window.innerWidth > 950&&this.$route.name=='AppLevelProducts') {
            document.body.classList.add("zoom-browser");
            $('#logged-cnt').addClass('level-prod')
            //  var element = document.getElementById("logged-cnt");
            //   element.classList.add("level-prod");
        }
        else {
          document.body.classList.remove("zoom-browser");
          $('#logged-cnt').removeClass('level-prod')
        }
    }
  }
}
</script>

<style lang="scss">

*{
  font-family: $medium-main;
}

.logged-cnt {
  width: calc(100vw - 50px);
  margin-left: auto;
  box-sizing: border-box!important;
}

.vs-sidebar-content  {
  position: fixed!important;  
}

// Fonts
// @font-face {
//     font-family: "Montserrat-Light";
//     src: local("Montserrat-Light"),
//     url(~@/assets/fonts/montserrat/Montserrat-Regular.ttf) format("truetype");
// }
// $main-light:Montserrat-Light;

// @font-face {
//     font-family: "Montserrat-Medium";
//     src: local("Montserrat-Medium"),
//     url(~@/assets/fonts/montserrat/Montserrat-Medium.ttf) format("truetype");
// }
// $main-medium:Montserrat-Medium;

// @font-face {
//     font-family: "Montserrat-SemiBold";
//     src: local("Montserrat-SemiBold"),
//     url(~@/assets/fonts/montserrat/Montserrat-SemiBold.ttf) format("truetype");
// }
// $main-semibold:Montserrat-SemiBold;

// @font-face {
//     font-family: "Montserrat-Bold";
//     src: local("Montserrat-Bold"),
//     url(~@/assets/fonts/montserrat/Montserrat-Bold.ttf) format("truetype");
// }
// $main-bold:Montserrat-Bold;

*{
  margin: 0;
  padding: 0;
}

h1 {
  font-family: $light-main;
}

	.vs-input-content {
		// margin: 10px 0px;
		width: calc(100%);
		.vs-input {
			width: 100%;
		}
	}

  .vs-select, .vs-select-content  {
    width: 100%!important;
  }

  .vs-select-content {
    max-width: 100%!important;;
  }

  @media only screen and (max-width: 1400px) {
    .logged-cnt { 
      padding-left: 0;
      padding-right: 0;
    }
    .app-main__sections-el {
      margin: .5rem!important; 
    }
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}
::-webkit-scrollbar
{
	height: 7px;
	background-color: #F5F5F5;
  width: 8px;
}
::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
					left bottom,
					left top,
					color-stop(0.44, #de5038),
					color-stop(0.72, #de5038), 
					color-stop(0.86, #de5038));
}

.vs-table {
  max-height: calc(100vh - 100px);
}

.vs-table__th {
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

.vs-table__td {
  min-width: 120px!important;
  padding-top:20px!important;
  padding-bottom: 20px!important;
}
.vs-table__th,
.vs-table__td {
  padding-left: 20px!important;
  padding-right: 20px!important;; 
}

.vs-card__img {
  max-height: 1000px!important;
  width: 250px;
  height:362px
}


.zoom-browser {
  -moz-transform: scale(0.8, 0.8);
   zoom: 0.8;
   zoom: 70%;
}
.level-prod {
  width: 98%!important;
  margin-left: auto;
}

</style>
