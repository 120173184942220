<template>
    <div class="app-sidebar" v-if="$store.state.sidebar">
        <template>
            <div class="hidden">
            <vs-sidebar
                fixed
                hover-expand
                reduce
                v-model="active"
                open
                >
                <template #logo >
                    <img style="cursor:pointer;" @click="goLink('/comenzi')" :src="$store.state.sidebar.header.logoimage" alt="logo">
                </template>
                <vs-sidebar-item v-for="(p, index) in $store.state.sidebar.pages.filter(page=>page.show==true)"  :key="index+123" :id="p.text" :to="p.link">
                <template #icon>
                    <i :class='p.icon'></i>
                </template>
                 {{p.text}}    
                </vs-sidebar-item>
                <!-- <vs-sidebar-item id="market">
                <template #icon>
                    <i class='bx bx-grid-alt'></i>
                </template>
                Market Overview
                </vs-sidebar-item>
                <vs-sidebar-item id="Music">
                <template #icon>
                    <i class='bx bxs-music'></i>
                </template>
                Music
                </vs-sidebar-item>
                <vs-sidebar-group>
                <template #header>
                    <vs-sidebar-item arrow>
                    <template #icon>
                        <i class='bx bx-group'></i>
                    </template>
                    Social media
                    </vs-sidebar-item>
                </template>

                <vs-sidebar-item id="Instagram">
                    <template #icon>
                    <i class='bx bxl-instagram'></i>
                    </template>
                    Instagram
                </vs-sidebar-item>
                <vs-sidebar-item id="twitter">
                    <template #icon>
                    <i class='bx bxl-twitter' ></i>
                    </template>
                    Twitter
                </vs-sidebar-item>
                <vs-sidebar-item id="Facebook">
                    <template #icon>
                    <i class='bx bxl-facebook' ></i>
                    </template>
                    Facebook
                </vs-sidebar-item>
                </vs-sidebar-group>
                <vs-sidebar-group>
                <template #header>
                    <vs-sidebar-item arrow>
                    <template #icon>
                        <i class='bx bx-code-alt' ></i>
                    </template>
                    Coding
                    </vs-sidebar-item>
                </template>

                <vs-sidebar-item id="github">
                    <template #icon>
                    <i class='bx bxl-github' ></i>
                    </template>
                    Github
                </vs-sidebar-item>
                <vs-sidebar-item id="codepen">
                    <template #icon>
                    <i class='bx bxl-codepen'></i>
                    </template>
                    Codepen
                </vs-sidebar-item>
                <vs-sidebar-item id="discord">
                    <template #icon>
                    <i class='bx bxl-discord'></i>
                    </template>
                    Discord
                </vs-sidebar-item>
                <vs-sidebar-item id="Javascript">
                    <template #icon>
                    <i class='bx bxl-javascript' ></i>
                    </template>
                    Javascript
                </vs-sidebar-item>
                <vs-sidebar-item id="git">
                    <template #icon>
                    <i class='bx bxl-git' ></i>
                    </template>
                    Git
                </vs-sidebar-item>
                </vs-sidebar-group>
                <vs-sidebar-item id="donate">
                <template #icon>
                    <i class='bx bxs-donate-heart' ></i>
                </template>
                Donate
                </vs-sidebar-item>
                <vs-sidebar-item id="drink">
                <template #icon>
                    <i class='bx bx-drink'></i>
                </template>
                Drink
                </vs-sidebar-item>
                <vs-sidebar-item id="shopping">
                <template #icon>
                    <i class='bx bxs-shopping-bags'></i>
                </template>
                Shopping
                </vs-sidebar-item> -->
                <div @click="logoutUser">
                <vs-sidebar-item id="chat" >
                <template #icon>
                    <i class='bx bx-log-out'></i>
                </template>
                Logout
                </vs-sidebar-item>
                </div>
                <template #footer>
                <vs-row justify="space-between">
                    <!-- <vs-avatar badge-color="primary" badge-position="top-right">
                    <i class='bx bx-bell' ></i>

                    <template #badge>
                        28
                    </template>
                    </vs-avatar> -->
                    <div style="max-width:100%;">
                    <span class="type-admin">
                        {{$store.state.sidebar.header.admininfo.type}}  
                    </span>
                    </div>
                    <vs-avatar circle primary>
                        <template #text class="sidebar-name">
                        {{$store.state.sidebar.header.admininfo.name}}  
                        </template>
                    </vs-avatar>
                </vs-row>
                </template>
            </vs-sidebar>
            </div>
        </template>
    </div>
</template>

  <script>
  export default {
    data:() => ({
      active: 'Home',
    }),
    methods:{
        logoutUser(){
            this.axios.get(`/logout?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
                console.log(response)
            })
            this.$cookies.remove("token");
            this.$cookies.remove("admin_id");
            window.location = "/"
        },
        goLink(link) {
            window.location = link;
        }
    }
  }
  </script>

  <style lang="scss" scoped>
  .type-admin {
      display: inline-block;
      max-width: 100%;
      width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-family: $medium-main;
        font-size: 10px;
  }


  </style>