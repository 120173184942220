import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Homepage from "../views/Homepage.vue"
import Main from "../views/Main.vue"
import WorkLogs from "../views/WorkLogs.vue"
import Adminlogs from "../views/AdminLogs";
import Processing from "../views/Processing.vue";
import HistoryLogs from "../views/HistoryLogs.vue";
import HistoryUser from "../views/HistoryUser.vue";
import Marketing from "../views/Marketing.vue";
import GoogleAds from "../views/GoogleAds.vue"
import GoogleAds2 from "../views/GoogleAds2.vue"
import Words from "../views/Words.vue";
import Contact from "../views/Contact.vue";
import BeautifulProducts from "../views/BeautifulProducts.vue";
import debugalex from "../views/debugalex.vue"
import AutoUpdate from "../views/AutoUpdate.vue"
import Campaigns from "../views/Campaigns.vue"
import SingleCampaign from "../views/SingleCampaign.vue"
import SelectOlxProducts from "../views/OLXProducts.vue"
import AppLevelProducts from "../views/AppLevelProducts.vue"
import AppTopSort from "../views/AppTopSort";
import ChangeHeader from "../views/ChangeHeader.vue";
import TrackUsers from "../views/TrackUsers.vue";
import Comenzi from "../views/Comenzi.vue";
import EditComanda from "../views/EditComanda.vue";
import Contabilitate from "../views/Contabilitate.vue"
import ListUsers from "../views/ListUsers.vue"
import VoucherStats from "../views/VoucherStats.vue"
import Courierstats from "../views/CourierStats.vue"
import GeneralInfo from "../views/GeneralInfo.vue"
import AddProduct from "../views/AddProduct.vue"

// Delete
import syncBrandsOlx from "../views/syncBrandsOlx.vue";
import sendCsv from "../views/sendCsv.vue";
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Homepage
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path:"/worklogs",
    name:"WorkLogs",
    component:WorkLogs
  },
  {
    path:"/loginlogs",
    name:"Adminlogs",
    component:Adminlogs
  },
  {
    path:"/generallist/:id",
    name:"Processing",
    component:Processing
  },
  {
    path:"/istoricorder/:id?",
    name:"HistoryLogs",
    component:HistoryLogs
  },
  {
    path:"/istoricactionuser/:id?",
    name:"HistoryUser",
    component:HistoryUser
  },
  {
    path:"/syncbrandsolx",
    name:"syncbrandsolx",
    component:syncBrandsOlx
  },
  {
    path:"/sendtocsv",
    name:"sendtocsv",
    component:sendCsv
  },
  {
    path:"/marketing",
    name:"marketing",
    component:Marketing
  },
  {
    path:"/generategooglesearchads",
    name:"g-ads",
    component:GoogleAds
  },
  {
    path:"/generatetrafficdisplay",
    name:"g-ads2",
    component:GoogleAds2
  },
  {
    path:"/searchwords",
    name:"words",
    component:Words
  },
  {
    path:"/contact",
    name:"contact",
    component:Contact
  },
  {
    path:"/selectbeautifulproducts/:id?",
    name:"selectbeautifulproducts",
    component:BeautifulProducts
  },
  {
    path:"/selectolxproducts/:id?",
    name:"selectolxproducts",
    component:SelectOlxProducts
  },
  {
    path:"/debugalex",
    name:"debugalex",
    component:debugalex
  },
  {
    path:"/autoupdate",
    name:"autoupdate",
    component:AutoUpdate
  },
  {
    path:"/campains",
    name:"campains",
    component:Campaigns
  },
  {
    path:"/singlecampain/:id",
    name:"singlecampain",
    component:SingleCampaign
  },
  {
    path:"/levelproducts",
    name:"AppLevelProducts",
    component:AppLevelProducts
  },

  {
    path:"/listsortedproducts",
    name:"AppTopSort",
    component:AppTopSort
  },
  {
    path:"/change-header",
    name:"ChangeHeader",
    component:ChangeHeader
  },
  {
    path:"/listtrackedusers/:value?",
    name:"TrackUsers",
    component:TrackUsers
  },
  {
    path:"/comenzi",
    name:"Comenzi",
    component:Comenzi
  },
  {
    path:"/editeazacomanda/:id",
    name:"EditComanda",
    component:EditComanda
  },
  {
    path:"/contabilitate",
    name:"contabilitate",
    component:Contabilitate
  },
  {
    path:"/listusers",
    name:"list-users",
    component:ListUsers
  },
  {
    path:"/voucherstats",
    name:"voucher-users",
    component:VoucherStats
  },
  {
    path:"/courierstats",
    name:"courierstats-users",
    component:Courierstats
  },
  {
    path:"/generalinfo",
    name:"general-info",
    component:GeneralInfo
  },
  {
    path:"/adaugaremanualaprodus",
    name:"add-product",
    component:AddProduct
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//       if (!loggedIn) {
//           next({
//               path: "/login",
//               query: { redirect: to.fullPath }
//           });
//       } else {
//           next();
//       }
//   } else {
//       next();
//   }
// });

export default router
