<template>
    <div class="work-logs">
      <AppTitle title="Employers Logs" style="padding:2rem 0;color:black;"/>
        <vs-table >
        <template #thead>
          <vs-tr style="color:black;">
            <vs-th>
              OrderID
            </vs-th>
            <vs-th>
              User
            </vs-th>
            <vs-th>
              Action
            </vs-th>
            <vs-th>
              Message
            </vs-th>
            <vs-th>
              Time
            </vs-th>
            <vs-th>
              Buy Price
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in logs"
            :data="tr"
            :style="tr.color" 
        class="work-logs__log"
          >
            <vs-td style="padding:1rem;">
              {{tr.OrderID}}
            </vs-td>
            <vs-td>
             {{tr.User}}
            </vs-td>
            <vs-td>
             {{tr.Action}}
            </vs-td>
            <vs-td>
             {{tr.Message}}
            </vs-td>
            <vs-td>
             {{tr.Time}}
            </vs-td>
            <vs-td>
             {{tr.Buy_Price}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
</template>


<script>
export default {
    data(){
        return {
            logs:[],
        }
    },
    beforeMount() {
        this.axios.get(`/worklogs?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
            response = response.data;
            console.log(response) 
            this.logs = response.table;
            this.$store.commit("VALIDATE_TOKEN",response.validsession)
        })
    }
}
</script>

<style lang="scss" scoped>
.work-logs {
    padding: 0  1rem;
    color: white;
    font-family: $semibold-main;

    &__log {
        cursor: pointer;

        &:hover {
            color: black;
        }
    }
}
</style>