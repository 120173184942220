<template>
    <div class="app-product">
        <img style="cursor:pointer;" :src="produs.picture" alt="" @click="goLink(produs.link)"> 
        <div class="app-product__table">
            <vs-table striped>
    <template #thead>
      <vs-tr>
        <vs-th>
          
        </vs-th>
        <vs-th>
          
        </vs-th>
        <vs-th>
          
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr

      >
        <vs-td @click="goLink(produs.link)" style="cursor:pointer;"> 
         {{produs.nume_produs}}
         <br>
         Cod produs: {{produs.cod_produs}}
        </vs-td>
        <vs-td>
      {{produs.bucati}}
        </vs-td>
        <vs-td>
      {{produs.total_produs}}
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
        </div>
  
    </div>
</template>

<script>
export default {
    props:{
        produs:{
            type:Object,
            default:()=>{}
        }
    },
    methods:{
        goLink(url) {
            window.open(url, '_blank').focus();
        }
    }
}
</script>

<style lang="scss" scoped>
.app-product {
    display: flex;
}
</style>