<template>
    <div class="app-beautiful-products">
        <div>
            <div style="display:flex;">
                <vs-radio v-model="level" :val="g.value" v-for="g in levels" :key="g.value">
                    {{g.name}}
                </vs-radio>
            </div>
            <span 
                style="display:block;margin-top:15px;"
                v-for="(w, index) in workers" :key="index+99">
                    {{w.name}} - {{w.value}}
            </span>
             <div class="app-beautiful-products__prod">
             <vs-card type="2" v-for="(p, index) in products" @click="sendProduct(p.ID, index)" :key="p.ID" style="margin-top:50px;"> 
            <template #title>
            <vs-button class="btn-chat" icon dark>
                <!-- <i class='bx bx-chat' ></i> -->
                <span class="span">
                 {{p.discount_price}} RON
                </span>
            </vs-button>
            <h3 style="height:50px;">
                <a target="_blank" :href="p.link" style="font-size:1.2rem;text-decoration:none;color:white">{{p.product_name}} 
                </a>npm
            </h3>

            
            </template>
            <template #img>
            <img :src="p.picture" alt="">
            </template>
            <template #text>
            <p>
            </p>
            </template>
            <template #interactions>
            <!-- <vs-button danger icon v-if="p.level==0">
                <i class='bx bx-heart'></i>
            </vs-button>
            <vs-button danger icon v-if="p.level==0">
                <i class='bx bx-heart'></i>
            </vs-button> -->
            <vs-button dark icon v-if="p.level!=1" @click="changeLevel(index,1)">
                <i class='bx bx-like' ></i>1
            </vs-button>
            <vs-button danger icon v-if="p.level==1">
                <i class='bx bx-like' ></i>1
            </vs-button>
            <vs-button danger icon v-if="p.level==2">
                <i class='bx bx-bookmark' ></i>2
            </vs-button>
            <vs-button danger icon v-if="p.level==3">
                <i class='bx bx-dislike'></i>3
            </vs-button>
            <vs-button danger icon v-if="p.level==4">
                <i class='bx bx-hide' ></i>4
            </vs-button>
            <vs-button danger icon >
                {{p.level_mark_user}}  
            </vs-button>
            </template>
        </vs-card>
            </div>
            <!-- <div style="max-width:250px;margin-top:20px;">
                <vs-select placeholder="Select" v-model="category" @change="loadPage" :key="categories.length">
                    <vs-option :label="c.name" :value="c.value" v-for="(c, index) in categories" :key="index+222">
                        {{c.name}}
                    </vs-option>
                    
                </vs-select>
            </div> -->
        </div>
            
    </div>
</template>


<script>
export default {
    data() {
        return {
            products:[],
            marked:[],
            brand:"",
            brands:[],
            done:"",
            gender:1,
            levels:[],
            category:"",
            categories:[],
            level:1,
            workers:[]
        }
    },
    methods:{
        sendProduct(id, index) {
            this.axios.get(`/markolxproduct?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&olxproduct=${id}`).then((response) => {
                console.log(response.data)
                this.marked[index] = true;
                this.products = [...this.products]
                console.log(this.marked)
          })  
        },
        async changeLevel(index, level){
            this.products[index].level = level

            await this.axios.get(`/levelproducts?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&markproductid=${this.products[index].ID}&marklevel=${level}`).then((response) => {
                 console.log(response.data)
            })
        },
        goSelect(id){
            window.location = "/selectolxproducts/"+id
        },
        loadPage(){
            const loading = this.$vs.loading()
            // else  id = "";
            this.axios.get(`/listsortedproducts?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&select=${this.gender}&level=${this.level}`).then((response) => {
                 console.log(response.data)
            //      this.done = response.data.done;
            //      this.products = []
            //      this.categories = [...response.data.subselect]
                if(response.data.listproducts!=null)
                    this.products = [...response.data.listproducts]
            //     this.marked=[...this.products];
            //     this.brands = response.data.selectbrand;
               this.levels = response.data.levelselect;
               this.workers = response.data.workers;
            //     if(this.$route.params.id) this.brand = [this.$route.params.id];
            //     console.log(this.brand)
                loading.close()
            })
        }
    },
    watch:{
        level(){
            this.category = "";
            this.loadPage();
        }
    },
    beforeMount() {
        this.loadPage()
    }
}
</script>


<style lang="scss" scoped> 
.app-beautiful-products { 
    padding: 2rem;;

    &__prod {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

</style>