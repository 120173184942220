<template>
    <div class="app-comenzi" v-if="btns.length">
               <vs-row >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" xs="12" sm="3" lg="2"  v-for="(b, index) in boxes.filter(box => box.show == true)" :key="index+928932">
                    <div class="btn-cnt">
                        <vs-button
                            class="app-main__btn"
                            :color="b.color"
                            :active="true"
                            :style="'background-color:'+b.color+'!important'"
                            @click="goLink(b.link)"
                        >
                        <div class="box-cnt">
                            <span class="box-cnt__title">{{b.text}}</span> 
                            <span class="box-cnt__stats">{{b.number}}</span>
                        </div>
                    </vs-button>
                    </div>
                </vs-col>
                </vs-row>
        <div class="app-comenzi__top">
            <!-- <h4>Lista comenzi</h4> -->
                <div class="app-comenzi__top-search">
            <vs-select placeholder="Nr.comanda" style="width:20px;!important;" :key="searchfilter.length" v-model="value">
                <vs-option v-for="(s,index) in searchfilter" :key="index" :label="s.name" :value="s.searchtype">
                {{s.name}}
                </vs-option>
            </vs-select>
            <div>&nbsp;</div>
            <vs-input  placeholder="Cauta..." v-model="searchvalue" />
            <vs-button
            @click="loadPage" 
            style="width:50px;"
                square
                icon
                color="rgb(255, 130, 0)"
                relief
            >
            <i class='bx bx-search'></i>
            </vs-button>
            </div>
        </div>
            <div class="app-comenzi__cnt">
                <div class="app-comenzi__cnt-btns">
                    <div                     v-for="(b, index) in btns"
                    :key="index">
                    <vs-button
                    square
                    color="dark" 
                    v-if="b.active==false"
                    @click="loadPage(b.parameter)"
                    flat
                    >
                        {{b.name}} ({{b.number}}) 
                    </vs-button>
                    <vs-button
                    square
                        v-else
                        @click="loadPage(b.parameter)"
                        >
                            {{b.name}} ({{b.number}})
                        </vs-button>
                    </div>
                </div>
                <!-- <div class="app-comenzi__cnt-downloads">
                    <span><i class='bx bx-download' ></i>Descarca lista comenzi</span>
                    |
                    <span><i class='bx bx-download' ></i>Descarca detalii comenzi</span>
                </div> -->
                <span v-if="!showlist" style="margin-top:1rem;display:block;">  Pentru a afisa informatiile dorite este necesar sa selectezi cel putin un criteriu de cautare de mai sus

</span>
                <div class="app-comenzi__cnt-table" v-if="showlist">
                    
<vs-table >
    <template #thead>
      <vs-tr>
          <!-- <vs-th>
              <vs-checkbox
                :indeterminate="selected.length == listorders.length" v-model="allCheck"
                @change="selected = $vs.checkAll(selected, listorders)"
              />
            </vs-th> -->
        <vs-th>
          ID comanda
        </vs-th>
        <vs-th>
          Nr. comanda client
        </vs-th>
        <vs-th>
          Detalii client
        </vs-th>
        <vs-th>
          Data plasare
        </vs-th>
                <!-- <vs-th>
          Data maxima finalizare
        </vs-th>
        <vs-th>
         Data maxima predare	
        </vs-th> -->
                <vs-th>
         Mod de plata si livrare	
        </vs-th>
        <vs-th>
          Detalii livrare
        </vs-th>
                <vs-th>
        Status
        </vs-th>
        <vs-th>
        Retur
        </vs-th>
        <vs-th>
        Voucher
        </vs-th>
        <vs-th>
          Total RON
(Cu TVA)
        </vs-th>
         <vs-th>
            Intra in cont
        </vs-th>
        <vs-th>
            Actiuni
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i"
        v-for="(tr, i) in listorders"
        :data="tr"
      >
            <!-- <vs-td checkbox>
              <vs-checkbox :val="tr" v-model="selected" />
            </vs-td> -->
        <vs-td>
         {{tr.id_comanda}}
        </vs-td>
                <vs-td>
         {{tr.nr_comanda_client}} 	 
        </vs-td>
        <vs-td>
         <b>{{tr.detalii_client.nume}}</b>
         <br>
            {{tr.detalii_client.telefon}}
        </vs-td>
        <vs-td>
         <span>
            <i class='bx bx-calendar' ></i>   
             {{tr.data_plasare.data}}
        </span>	
        <br>
                 <span>
            <i class='bx bx-stopwatch' ></i> 
              {{tr.data_plasare.ora}} 
        </span>	
        </vs-td>
        <!-- <vs-td>
         <span>
            <i class='bx bx-calendar' ></i>   
             05/02/2022  
        </span>	
        </vs-td>
        <vs-td>
         <span>
            <i class='bx bx-calendar' ></i>   
             05/02/2022  
        </span>	
        </vs-td> -->
        <vs-td>
            {{tr.mod_de_plata_si_livrare.metoda_plata}} 
            <br>
{{tr.mod_de_plata_si_livrare.destination}} 
        </vs-td>
        <vs-td>
         <a href="#" style="color:black;border:none;text-decoration:none;">
             {{tr.detalii_livrare[0].name}} 
         </a>
         <br>
         <br>
            <a :href="tr.detalii_livrare[1].link" target="_blank" style="color:#337ab7;">
             {{tr.detalii_livrare[1].awb}} 
         </a>
        </vs-td>
        <vs-td>
        <vs-button
        style="font-size:10px;"
        :style="{'background-color':tr.status.color}"
            icon  
            >
            {{tr.status.name}}  
            </vs-button>
        </vs-td>
        <vs-td>
        <vs-button
        style="font-size:10px;"
        :style="{'background-color':tr.max_return.color}"
            icon  
            >
            {{tr.max_return.name}}    
            </vs-button>
        </vs-td>
                <vs-td>
        <vs-button
        style="font-size:10px;"
        :style="{'background-color':tr.voucher.color}"
            icon  
            >
            {{tr.voucher.name}}    
            </vs-button>
        </vs-td>
        <vs-td>
         {{tr.total_ron}} 

        </vs-td>
        <vs-td>
      <a style="text-decoration:none;" :href="tr.enter_account" target="_blank">
                     <vs-button
        style="font-size:10px;"
        :style="{'background-color':'black'}" 
            icon  
            >
            Cont   
            </vs-button>
      </a>

        </vs-td>
        
        <vs-td>
         <a :href="tr.edit_link" style="color:#337ab7;cursor:pointer;">
           <i class='bx bx-edit-alt' ></i>  
            Editeaza 
        </a>	
        <br>
                 <span style="display:block;color:#337ab7;cursor:pointer;margin-top:.5rem" >
            <i class='bx bx-detail' ></i>
              Detalii 
        </span>	
        </vs-td>
<template #expand >
              <div class="con-content" > 
                <div class="con-content--1">
                    <span class="con-content--1-title">Livrare seller</span>
                    <span class="con-content--1-el" v-for="n in tr.arata_detalii.detaliiclient" :key="n">{{n}}</span>
                </div>
                <div class="con-content--2">
                   <div v-for="(p, i) in tr.arata_detalii.produse_comanda" :key="i+998" style="padding:1rem 0;">
                        <AppProduct :produs="p"/> 
                   </div>
                   <span style="border-top:1px solid gray;display:block;padding-top:10px;">Taxe de livrare: {{tr.arata_detalii.taxa_de_livrare}} </span>
                    <span style="display:block;padding-top:10px;"><b>Total: {{tr.arata_detalii.Total}} </b></span>
                </div>
                <div class="con-content--3"> 
                    <div v-for="(n,index) in tr.arata_detalii.buttons" :key="index">
                        <a v-if="n.type=='link'" style="color:#337ab7!important;" :target="n.newlink" :href="n.link" >{{n.nume}}</a>
                        <a v-if="n.type=='history'" @click="openHistory(n.order_id)" style="color:#337ab7!important;"  href="#" >{{n.nume}}</a>
                    </div>
                </div>
              </div>
            </template>
      </vs-tr>
    </template>
  </vs-table>
           <div v-if="show_number_pages" style="width:200px;display:flex;justify-content:right;margin-left:auto;margin-right:20px;">
                <vs-select placeholder="Nr.comanda" style="width:20px;!important;" :key="pageNr.length" v-model="nractive">
                <vs-option v-for="(s,index) in pageNr" :key="index" :label="s.name" :value="s.value"> 
                {{s.name}}
                </vs-option>
            </vs-select>
           </div>
                </div>
            </div>
<vs-dialog scroll overflow-hidden not-close auto-width v-model="historyOpened">
        <template #header>
          <h3>
            Istoric 
          </h3>
        </template>
        <vs-table>
    <template #thead>
      <vs-tr>
        <vs-th>
          Admin
        </vs-th>
        <vs-th>
          Data
        </vs-th>
        <vs-th>
          Actiune
        </vs-th>
        <vs-th>
          Comentarii
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i+123"
        v-for="(tr, i) in history"
        :data="tr"
      >
        <vs-td>
          {{tr.admin}}
        </vs-td>
        <vs-td>
        {{tr.time}}
        </vs-td>
        <vs-td>
         {{tr.action}}
        </vs-td>
                <vs-td>
         {{tr.comments}} 
        </vs-td>
          
      </vs-tr>
    </template>
  </vs-table>
      </vs-dialog>
    </div>
</template>

<script>
import AppProduct from "../components/AppProduct.vue";
import $ from "jquery";
export default {
    components:{
        AppProduct
    },
    data(){
        return {
            searchfilter:[],
            btns:[],
            value:1,
                allCheck: false,
        selected: [],
        showlist:true,
        pageNr:[],
        showP:false,
        nractive:"",
        listorders: [],
        detalii:{},
        searchvalue:"",
        openedDetails:0,
        historyOpened:false,
        history:{},
        show_number_pages:true
        }
    },
    methods:{
        showDetails(detalii) {
            this.detalii= detalii;
            console.log(detalii)
        },
                goLink(link) {
            window.location = link;
        },
         openHistory(id) {
            
            this.historyOpened = true;
this.axios.get(`/historyorder?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&idcomanda=${id}`).then((response) => {
                response = response.data;
                this.history = response.orderhistory;
            })
        },
        loadPage(param){
            this.axios.get(`/comenzi?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&activeparameter=${param}&searchvalue=${this.searchvalue}&searchtype=${this.value}`).then((response) => {
                response = response.data;
                this.searchfilter = response.searchfilter;
                this.btns = response.ordertype;
                this.listorders = response.listorders;
                this.showlist = response.showlistorders;
                this.nractive = response.activepagina;
                this.pageNr = response.pepagina;
                this.showP = response.showpepagina;
                this.searchvalue = ""
                this.value = 1;
                this.boxes = response.small_boxes;
                this.show_number_pages = response.show_number_pages;
                console.log(response)
            })
        }
    },
    beforeMount(){
        this.loadPage("")
    },
    mounted(){
        $('.vs-select__input ').css('border-radius', '0px!important');
        $('.vs-input ').css('border-radius', '0px');
        $('.vs-input ').css('border-radius', '0px');
        $(".vs-input").css("borderColor", "1px solid black"); 
    }
}
</script>


<style lang="scss" scoped>
.vs-select__input {
    border-radius: 0!important;; 
}
.app-comenzi {
    padding:1rem;

    &__top {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        &-search {
            display: flex;
            padding: .5rem 1rem;
            margin-left: auto;
            box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.198);
            flex-wrap: wrap;
        }
    }

    &__cnt {

        &-btns {
            display: flex;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.233);
            flex-wrap: wrap;
        }

        &-downloads {
            padding: 1rem 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.233);

            span {
                font-size: .8rem!important; 
                cursor: pointer;
                
                &:hover {
                    border-bottom: 1px solid black;
                }
                 
                i {
                    transform: translateY(1px);
                    padding-right: 3px;
                }
            }
        }

    }
}

.con-content {
    display: flex;
    border-top: 1px solid rgb(192, 192, 192);;;
    padding: 20px;
    box-shadow: 3px 3px 5px 0 #de5038;

    &--1 {
        display: flex;
        flex-direction: column;
        width:20%;
        padding-right: 10px;
        border-right: 1px solid rgb(192, 192, 192);;

        &-title {
            display: block;
            border-bottom: 1px solid rgb(192, 192, 192);
            padding-bottom: 10px;
        }

        &-el {
            color: gray;

            &:first-child {
                display: block;
                margin-top: 20px; 
            }
        }
    }

    &--2 {
        padding: 0 2rem;
        width: 70%;
        border-right: 1px solid  rgb(192, 192, 192);;
    }

    &--3 {
        display: flex;
        flex-direction: column;
        margin-left: auto;

        a {
            display: block;
            font-size: .8rem;
            margin: 10px;
        }
    }
}



.box-cnt {
    // height: 100px;
    box-sizing: border-box;
    width:100%;

    span {
        display: block;
    }

    &__title {
        text-align: start;
        font-family: $light-main;
        font-size: 1.5rem;
    }

    &__stats {
        font-family: $bold-main;
        font-size: 2.5rem;
        text-align: start;
        margin-top: 1rem;
    }
}

.app-main {

    &__sections {

        &-el {
            display: flex; 
            flex-wrap: wrap;
            margin: 2rem;
        }
    }

    &__btn {
        width:100%;
        height: 100%;
    }
}

.btn-cnt {
    padding: .5rem;
    box-sizing: border-box;
}

h2 {
    font-family: $bold-main;
    padding-left: .8rem;
}


  @media only screen and (max-width: 600px) {
      .box-cnt__title
       {
          font-size: 1rem;
      }
      .box-cnt__stats{
          font-size: 16px;
      }
      .btn-cnt {
          padding:0rem;
      }
      h2 {
          padding-bottom: .5rem;
      }
  }

// @media only screen and (max-width: 1400px) {
//     .box-cnt {
//         width:100%;
//     }
//     .app-main__btn {
//         width:48%; 
//         margin-left: 0; 
//         margin-right: 0;
//     }
// }

// @media only screen and (max-width: 1150px) {
//     .box-cnt {
//         width:100%;
//     }
//     .app-main__btn {
//         width:100%;
//         margin-left: 0;
//         margin-right: 0;
//     }
// }
</style>