<template>
    <div style="padding-left:1rem;;overflow:hidden;" v-if="orders">
    <div> 
        <vs-table >
        <template #thead>
          <vs-tr style="color:black;">
            <vs-th v-for="(t, index) in orders.titles" :key="index+123">
              {{t}}
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody style="width:500vh!important;">  
          <vs-tr
            :key="i"
            v-for="(tr, i) in orders.contact"
            :data="tr"
            :style="tr.color" 
            class="work-logs__log"
          >
            <vs-td v-for="(v, index) in tr" :key="index">
            <div >
                 <span v-if="tr[index].show&&tr[index].type=='static'">{{tr[index].value}}</span> 
                <span v-if="tr[index].show&&tr[index].type=='html'" v-html="tr[index].value"></span> 
                <vs-button
                        @click="goLink(tr[index].redirectlink, tr[index].newtab)"
                        v-if="tr[index].show&&tr[index].type=='linkcuicon'"
                        icon
                        color="discord"
                    >
                    <i :class='tr[index].iconlink'></i>
                </vs-button>
                <div v-if="tr[index].show&&tr[index].type=='upload'">
                    <input type="file" @change="uploadBack(tr[index].uploadeorderid)" style="width:0;height:0;position:absolute;top:-534500000px;" :id="tr[index].uploadeorderid" :name="tr[index].uploadeorderid" :ref="tr[index].uploadeorderid">
                    <vs-button
                        @click="uploadFile(tr[index].uploadeorderid)"
                        icon
                        color="discord"
                    >
                    <i class='bx bx-upload' ></i>
                </vs-button>
                </div>
            <vs-button
            dark
            :style="tr[index].actioncolor"
            v-if="tr[index].show&&tr[index].type=='button_link'"
            @click="goLink(tr[index].redirectlink, tr[index].newtab)"
            >
                {{tr[index].value}}
            </vs-button>
            <vs-button
            :style="'background-color:'+tr[index].actioncolor"
            v-if="tr[index].show&&tr[index].type=='action'"
            @click="openModal(tr[index])"
            >
                {{tr[index].buttontext}} 
            </vs-button>
            <vs-button
            :style="'background-color:'+tr[index].actioncolor"
            v-if="tr[index].show&&tr[index].type=='markdone'"
            @click="markDone(tr[index].submitvalue,tr[index].actionpossible)"
            icon
            >
            {{tr[index].buttontext}} 
                <!-- <i class='bx bx-check'></i> -->
            </vs-button>
            <vs-button
            dark
            icon
            v-if="tr[index].show&&tr[index].type=='produse'"
            @click="openModal(tr[index])"
            >
                 <i class='bx bxs-cart'></i>
            </vs-button>
            <div v-if="tr[index].show&&tr[index].type=='hover'" class="block_expand" style="cursor:pointer;">
                <span>{{tr[index].value}}</span>
                <span class="block_expand-hidden">{{tr[index].hiddenvalue}}</span>
            </div>
            <div v-if="tr[index].show&&tr[index].type=='image'" >
                <img :src="tr[index].imagesource" alt="delivery">
            </div>
            <div v-if="tr[index].show&&tr[index].type=='waiting'" class="block_expand" style="cursor:pointer;">
                <span><i class='bx bx-time' ></i></span>
                <span class="block_expand-hidden">{{tr[index].hoverwaiting}}</span>
            </div>
            <div v-if="tr[index].show&&tr[index].type=='staticok'" class="block_expand" style="cursor:pointer;">
                <span style="color:green;font-size:1.5rem!important;"><i class='bx bx-check' ></i></span>
                <span class="block_expand-hidden">{{tr[index].hoverok}}</span>
            </div>
            </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <vs-dialog scroll overflow-hidden auto-width v-model="active" @close="closeModal" v-if="question">
        <div class="modal-content">
            <div class="modal-content__template">
                <span>Subiect: </span>
                <span>{{question.subiect}}</span> 
            </div>
            <div class="modal-content__template" v-if="question.showproduct">
                <span>Produs: </span>
                <a target="_blank" :href="question.linkproduct">{{question.linkproduct}}</a> 
            </div>
           <div class="modal-content__template">
                <span>Intrebare: </span>
                <span v-html="question.intrebare"></span> 
            </div>
            <div class="modal-content__template">
                <span>Raspuns: </span>
                <textarea v-model="response" name="" id="" cols="30" rows="10"></textarea>
            </div>
            <vs-button style="margin:0 auto;margin-top:1rem;" @click="sendResponse">
                Raspunde 
            </vs-button>
        </div>
    </vs-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return {
            orders:null,
            modal:false,
            order:{
                actionparameter:"",
                actionorderid:"",
                reason:"",
                achizitie:"",
                showpret:false, 
                err:""
            },
            upload_id:"",
            dialog_select:0,
            showtext:false,
            motive:[],
            fullscreen:false,
            comanda:null,
            editActive:false,
            edit:null,
            editIndex:1,
            removeReason:"",
            removeReasontxt:"",
            copyEditValue:"",
            editValue:null,
            test:false,
            active:false,
            question:null,
            response:""
        }
    },
    methods:{
        markDone(val,p){
            if(p)
                this.axios.get(`/contact?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&markdone=${val}`).then((response) => {
                    response = response.data;
                    this.orders = response; 
                    console.log(response)
                })
        },
        openModal(question) {
            if(question.actionpossible)
            this.active = true;
            this.question = question;
        },
        closeModal(){
            this.question = null;
            this.response = "";
        },
        sendResponse(){
            this.axios.get(`/contact?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&contactid=${this.question.contactid}&textraspuns=${this.response.replace(/\n/g, "***")}`).then((response) => {
                response = response.data;
                this.orders = response; 
                console.log(response)
                this.closeModal()
                location.reload()
            }) 
        },
        loadPage(){
            this.axios.get(`/contact?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
                response = response.data;
                this.orders = response; 
                console.log(response)
            })
        }
    },
   beforeMount(){
       this.loadPage();
   } 
}
</script>

<style lang="scss" scoped>
textarea {
    width: 100%;
    margin-top: .5rem;
    resize: none;
    outline: none;
    height: 200px;
    padding: 1rem;
    font-family: $light-main;
    border: 1px solid rgb(202, 202, 202);
    border-radius: 10px;
}
.block_expand {
    overflow: hidden;
    transition: .6s;
    padding: 1rem 0;
}

.block_expand-hidden {
    display: none;
}

.block_expand:hover > .block_expand-hidden {
    display: block!important;
}
.modal-content {
    padding: 2rem;

    &__template {
        margin-top: 1rem;
    }
}
</style>


