<template>
    <div class="app-main">
        <!-- {{boxes}} -->
        <div class="app-main__sections">
            <div class="app-main__sections-el" v-for="(box,index) in boxes.filter(box => box.show ==true)" :key="index">
                <AppTitle :title="box.title"/>
                <vs-row >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" xs="12" sm="6" lg="3"  v-for="(b, index) in box.box.filter(box => box.show == true)" :key="index+928932">
                    <div class="btn-cnt">
                        <vs-button
                            class="app-main__btn"
                            :color="b.color"
                            :active="true"
                            :style="'background-color:'+b.color+'!important'"
                            @click="goLink(b.link)"
                        >
                        <div class="box-cnt">
                            <span class="box-cnt__title">{{b.text}}</span> 
                            <span class="box-cnt__stats">{{b.number}}</span>
                        </div>
                    </vs-button>
                    </div>
                </vs-col>
                </vs-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            boxes:[]
        }
    },
    methods:{
        goLink(link) {
            window.location = link;
        },
        getMain(){
            this.axios.get(`/marketing?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
                response = response.data;
                this.boxes = response.boxes;
                console.log(response)
                this.$store.commit("VALIDATE_TOKEN",response.validsession)
            })
        }
    },
    beforeMount(){
        console.log(this.$store.state.adminid)
        this.getMain();
    },
    mounted(){
        window.setInterval(() => {
            this.getMain()
        }, 2000)
    }
}
</script>


<style lang="scss" scoped>
.box-cnt {
    // height: 100px;
    box-sizing: border-box;
    width:100%;

    span {
        display: block;
    }

    &__title {
        text-align: start;
        font-family: $light-main;
        font-size: 1.5rem;
    }

    &__stats {
        font-family: $bold-main;
        font-size: 2.5rem;
        text-align: start;
        margin-top: 1rem;
    }
}

.app-main {

    &__sections {

        &-el {
            display: flex; 
            flex-wrap: wrap;
            margin: 2rem;
        }
    }

    &__btn {
        width:100%;
    }
}

.btn-cnt {
    padding: .5rem;
    box-sizing: border-box;
}

h2 {
    font-family: $bold-main;
    padding-left: .8rem;
}


  @media only screen and (max-width: 600px) {
      .box-cnt__title
       {
          font-size: 1rem;
      }
      .box-cnt__stats{
          font-size: 16px;
      }
      .btn-cnt {
          padding:0rem;
      }
      h2 {
          padding-bottom: .5rem;
      }
  }

// @media only screen and (max-width: 1400px) {
//     .box-cnt {
//         width:100%;
//     }
//     .app-main__btn {
//         width:48%; 
//         margin-left: 0; 
//         margin-right: 0;
//     }
// }

// @media only screen and (max-width: 1150px) {
//     .box-cnt {
//         width:100%;
//     }
//     .app-main__btn {
//         width:100%;
//         margin-left: 0;
//         margin-right: 0;
//     }
// }
</style>