<template>
    <div class="app-words">
        <span style="display:block;" v-for="(w,index) in words" :key="index">{{w}}</span> 
    </div>
</template>

<script>
export default {
    data(){
        return {
            words:[]
        }
    },
    beforeMount(){
        const loading = this.$vs.loading()
        this.axios.get(`/searchwords?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
            response = response.data;
            this.words = response.words;
            console.log(response)
            loading.close()
          })
    }
}
</script>

<style lang="scss" scoped>
.app-words {
    margin-left: 2rem;
    margin-top: 1rem;
    font-family: $medium-main;

    span {
        padding: .4rem;
    }
}
</style>