<template>
    <div class="track-users">
       <div style="display:flex;flex-wrap:wrap;" v-if="!$route.params.value"> 
            <a style="padding:1rem;text-decoration:none;" v-for="n in users.filter(el=>el.show)" :key="n" :href="'/listtrackedusers/'+n.value"> 
                <vs-avatar circle danger  style="cursor:pointer;" v-if="n.nractions<2">
                <template #text>
                    {{n.user}}
                </template>
                </vs-avatar>
                <vs-avatar circle success style="cursor:pointer;" v-if="n.nractions>1&&n.nractions<6">
                <template #text>
                    {{n.user}}
                </template>
                </vs-avatar>
                <vs-avatar circle dark style="cursor:pointer;" v-if="n.nractions>5">
                <template #text>
                    {{n.user}} 
                </template>
                </vs-avatar>
            </a>
       </div>
       <div v-else>
        <vs-button size="xl" @click="markTrack" style="margin-left:auto;">Marcheaza</vs-button>
        <vs-table stripped>
            <template #thead>
            <vs-tr style="color:black;">
                <vs-th>
                Ruta
                </vs-th>
                <vs-th>
                Domeniu
                </vs-th>
                <vs-th>
                Actiune
                </vs-th>
                <vs-th>
                Data
                </vs-th>
                <vs-th>
                Oras
                </vs-th>
                <vs-th>
                IP
                </vs-th>
                <vs-th>
                Resolution
                </vs-th>
                <vs-th>
                User Agent
                </vs-th>
            </vs-tr>
            </template>
            <template #tbody>
            <vs-tr
                :key="i"
                v-for="(tr, i) in userhistory"
                :data="tr"
                :style="tr.color" 
                class="work-logs__log"
            >
                <vs-td style="padding:1rem;">
                    <a style="text-decoration:none;cursor:pointer;" :href="'https://'+tr.domain+tr.route" target="_blank">{{tr.route}}</a>
                </vs-td>
                <vs-td>
                {{tr.domain}}
                </vs-td>
                <vs-td>
                {{tr.action}}
                </vs-td>
                <vs-td>
                {{tr.time}}
                </vs-td>
                <vs-td>
                {{tr.city}}
                </vs-td>
                <vs-td>
                {{tr.ip}}
                </vs-td>
                <vs-td>
                {{tr.resolution}}
                </vs-td>
                <vs-td>
                {{tr.user_agent}}
                </vs-td>
            </vs-tr>
            </template>
        </vs-table>
           <!-- <div v-for="u in userhistory" :key="u"> 
            {{u}} 
           </div> -->
       </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            users:[],
            userhistory:[]
        }
    },
    methods:{
        goLink(val) {
            this.$router.push("/listtrackedusers/" + val);
        },
        markTrack(){
            this.axios.get(`/listtrackedusers?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&domain=${this.domain}&markvalue=${this.$route.params.value}`).then(() => {
                window.location =  "/listtrackedusers"
                // history.back()
            })
        },
        loadPage(){
            const loading = this.$vs.loading()
            // else  id = "";
            this.axios.get(`/listtrackedusers?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&domain=${this.domain}&value=${this.$route.params.value}`).then((response) => {
                if(response.data.trackedusers)
                    this.users = [...response.data.trackedusers];
                if(response.data.userhistory)
                    this.userhistory = [...response.data.userhistory];
                console.log(response)
                loading.close()
            })
        }
    },
    beforeMount(){
        this.loadPage()
    },
    watch:{
        "$route.fullPath":function() {
            this.loadPage();
        }
    }
}
</script>

<style lang="scss" scoped>
.track-users {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}
</style>