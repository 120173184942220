<template>
    <div class="singlecampaign">
        <vs-dialog  v-model="dialog" @close="goBack">
        <template #header>
          <h4 class="not-margin">
            Genereaza link final
          </h4>
        </template>
        <vs-input
            label-placeholder="Link"
            v-model="link"
        />
        <div style="margin-top:10px;font-size:13px;padding:10px;">
            <span>Rezultat:</span>
            <span style="display:block;cursor:pointer;" @click="copyText">{{generated}}</span>
        </div>
      <vs-button
        style="margin:0 auto;margin-top:1rem;"
        @click="generateLink"
      >
        Genereaza
      </vs-button>
      </vs-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return {
            dialog:true,
            link:"",
            generated:""
        }
    },
    methods:{
        generateLink(){
            this.axios.get(`/singlecampain?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&link=${encodeURIComponent(this.link)}&campainid=${this.$route.params.id}`).then((response) => {
                response = response.data;
                this.generated = response.result;
                console.log(response)
            })
        },
        goBack(){
            history.back()
        },
        copyText(text){
            text = this.generated;
            var input = document.createElement('input');
            input.setAttribute('value', text);
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand('copy');
            document.body.removeChild(input);
            this.$vs.notification({
                    color:'success',
                    position:"top-right",
                    title: 'Succes',
                    text: 'Text copiat!'
            })
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>