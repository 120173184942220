import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import Cookies from  'js-cookie'

import axios from 'axios'

export default new Vuex.Store({
  state: {
    admintoken:Cookies.get("token"),
    adminid:Cookies.get("admin_id"),
    sidebar:null
  },
  mutations: {
    VALIDATE_TOKEN(state,valid) {
      if(valid!=true) {
        Cookies.remove('token');
        Cookies.remove("admin_id");
        window.location = "/";
      }
    },
    SET_SIDEBAR(state, sidebar) {
      state.sidebar = sidebar;
    }
  },
  actions: {
    sidebarGet({commit, state}) {
      axios.get(`/sidebar?adminid=${state.adminid}&admintoken=${state.admintoken}`).then((response) => {
          response = response.data;
          console.log(response) 
          commit("VALIDATE_TOKEN",response.validsession)
          commit("SET_SIDEBAR",response);
      })
    }
  },
  modules: {
  }
})
