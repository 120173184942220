<template>
    <div class="change-header">
    <vs-select placeholder="Select" v-model="domain" :key="domains.length" @input="loadPage" filter>
        <vs-option :label="b" :value="b" v-for="(b, index) in domains" :key="index">
          {{b}}
        </vs-option>
      </vs-select>
      <img :src="preview" alt="cc" style="max-width:100%;
      margin-top:30px;">
    </div>
</template>

<script>
export default {
    data(){
        return {
            domains:[],
            domain:"designmall.ro",
            preview:""
        }
    },
    methods:{
        loadPage(){
            const loading = this.$vs.loading()
            // else  id = "";
            this.axios.get(`/changeheader?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&domain=${this.domain}`).then((response) => {
                this.domains = [...response.data.selectdomain]
                this.preview = response.data.previewimage;
                console.log(response)
                loading.close()
            })
        }
    },
    beforeMount(){
        this.loadPage()
    }
}
</script>

<style lang="scss" scoped>
.change-header {
    padding: 2rem;
}
</style>