<template>
    <div class="app-list-users">
         <vs-table>
    <template #thead>
      <vs-tr>
        <vs-th>
          
        </vs-th>
        <vs-th>
          
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
        :key="i"
        v-for="(tr, i) in list"
        :data="tr"
      > 
        <vs-td>
          {{ tr.name }}
        </vs-td> 
        <vs-td>
          {{ tr.value }} 
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list:[]
        }
    },
    methods:{
        loadPage(){
            this.axios.get(`/courierstats?&adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}`).then((response) => {
                response = response.data;
                this.list = response.list;
            })
        }
    },
    beforeMount() {
        this.loadPage()
    }
}
</script>



<style lang="scss" scoped>
.app-list-users {
    padding:20px;
}
</style>