<template>
  <div class="edit-comanda">
    <div class="edit-comanda__top">
      <div class="edit-comanda__top-left">
        <i
          @click="$router.push('/comenzi')"
          style="cursor: pointer"
          class="bx bx-arrow-back"
        ></i>
        &nbsp;&nbsp; Comanda <b>{{ id_comanda }}</b> din data {{ data_comanda }}
      </div>
      <div class="edit-comanda__top-right">
        <vs-button
          v-for="(b, index) in btns.filter(
            (el) => el.show && el.type == 'link'
          )"
          :key="index + 545"
          square
          icon
          :color="b.color"
          relief
        >
          {{ b.name }}
        </vs-button>
        <vs-button
          @click="sendData"
          v-for="(b, index) in btns.filter(
            (el) => el.show && el.type == 'save'
          )"
          :key="index + 123"
          square
          icon
          :color="b.color"
          relief
        >
          {{ b.name }}
        </vs-button>
      </div>
    </div>
    <div style="display: flex; flex-wrap: wrap">
      <a
        style="text-decoration: none"
        :href="d.link"
        :target="d.target"
        v-for="(d, index) in download_invoice"
        :key="index + 665"
      >
        <vs-button
          square
          style="font-size: 9px"
          :style="{ 'background-color': d.color }"
          icon
        >
          {{ d.name }}
        </vs-button>
      </a>
    </div>
    <div class="edit-comanda__main">
      <div style="display: flex; justify-content: space-between">
        <div class="edit-comanda__main-template">
          <span class="edit-comanda__main-template__title"
            >Detalii comanda</span
          >
          <div class="edit-comanda__main-template__list">
            <div style="margin-top: 5px">
              <span>Metoda de plata: </span>
              <b>{{ m_plata }}</b>
            </div>
            <div style="margin-top: 5px">
              <span>Observatii client: </span>
              <b>{{ data_maxima }}</b>
            </div>
          </div>
        </div>
        <div class="edit-comanda__main-template">
          <span class="edit-comanda__main-template__title">Facturare</span>
          <div class="edit-comanda__main-template__list">
            <div
              v-for="(f, index) in facturare"
              :key="index + 98"
              style="margin-top: 5px"
            >
              <span>{{ f.name }} </span>
              <b>{{ f.value }}</b>
            </div>
          </div>
        </div>
        <div class="edit-comanda__main-template">
          <span class="edit-comanda__main-template__title">Livrare</span>
          <div class="edit-comanda__main-template__list">
            <div
              v-for="(f, index) in livrare_la_domiciliu"
              :key="index + 345"
              style="margin-top: 5px"
            >
              <span>{{ f.name }} </span>
              <b>{{ f.value }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="edit-comanda__main-bottom">
        <div style="margin-top: 10px; width: 300px">
          <vs-input v-model="observatii" placeholder="Observatii admin" />
        </div>
        <div style="width: 300px; margin-top: 10px">
          <vs-select
            placeholder="Select"
            v-model="c_type"
            :key="status_comanda.length"
          >
            <vs-option
              :label="s.name"
              :value="s.action"
              v-for="(s, index) in status_comanda"
              :key="index + 984"
            >
              {{ s.name }}
            </vs-option>
          </vs-select>
        </div>
        <div class="edit-comanda__main-bottom-btns" style="flex-wrap: wrap">
          <div v-for="(b, index) in small_buttons" :key="index + 2393">
            <vs-button v-if="!b.possible" disabled warning border>
              <i :class="b.icon"></i> {{ b.name }}
            </vs-button>
            <vs-button
              @click="openUpload = true"
              v-if="b.type == 'upload' && b.possible"
              warning
              border
            >
              <i :class="b.icon"></i> {{ b.name }}
            </vs-button>
            <vs-button
              @click="(actions = true), (btnT = b)"
              v-if="b.type == 'genereazaawbclient' && b.possible"
              warning
              border
            >
              <i :class="b.icon"></i> {{ b.name }}
            </vs-button>
            <vs-button
              @click="openUploadStorn = true"
              v-if="b.type == 'uploadstorno' && b.possible"
              warning
              border
            >
              <i :class="b.icon"></i> {{ b.name }}
            </vs-button>
            <a
              :href="b.link"
              v-if="b.type == 'link' && b.possible"
              style="text-decoration: none"
              :target="b.newlink"
            >
              <vs-button warning border>
                <i :class="b.icon"></i> {{ b.name }}
              </vs-button>
            </a>
            <vs-button
              @click="openHistory"
              v-if="b.type == 'history' && b.possible"
              warning
              border
            >
              <i :class="b.icon"></i> {{ b.name }}
            </vs-button>
            <vs-button
              @click="(actions = true), (btnT = b)"
              v-if="b.type == 'orderprocessing' && b.possible"
              warning
              border
            >
              <i :class="b.icon"></i> {{ b.name }}
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="edit-comanda__table">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th> Imagine </vs-th>
            <vs-th> Produs </vs-th>
            <vs-th> Cantitate </vs-th>
            <vs-th>
              Pret vanzare
              <br />
              (RON cu TVA)
            </vs-th>
            <vs-th> Discount </vs-th>
            <vs-th> Actiuni </vs-th>
            <vs-th> Comentariu </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(p, index) in listare_produse" :key="index + 93">
            <vs-td>
              <a
                style="color: #337ab7 !important; cursor: pointer"
                target="_blank"
                :href="p.link"
                ><img :src="p.picture" alt=""
              /></a>
            </vs-td>
            <vs-td style="width: 1000px">
              <a
                style="color: #337ab7 !important; cursor: pointer"
                target="_blank"
                :href="p.link"
                :key="index + 345"
                >{{ p.name }}
              </a>

              <br />
              <br />
              <a
                style="color: red !important; cursor: pointer"
                target="_blank"
                :href="p.line1"
                :key="index + 768"
                >Furnizor</a
              >

              <br />
              <br />
              {{ p.line2 }}
            </vs-td>
            <vs-td>
              {{ p.cantitate }}
            </vs-td>
            <vs-td>
              {{ p.pret_de_baza_cu_tva }}
            </vs-td>
            <vs-td>
              {{ p.discount }}
            </vs-td>
            <vs-td style="text-align: left">
              <vs-button
                @click="deleteProduct(p.deletevalue, p.comment)"
                style="font-size: 10px"
                color="danger"
                icon
              >
                Sterge
              </vs-button>
            </vs-td>
            <vs-td>
              <textarea
                style="outline: none; padding: 5px; font-size: 12px !important"
                v-model="p.comment"
                name=""
                id=""
                cols="20"
                rows="5"
              ></textarea>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <vs-dialog
      scroll
      overflow-hidden
      not-close
      auto-width
      v-model="historyOpened"
    >
      <template #header>
        <h3>Istoric</h3>
      </template>
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th> Admin </vs-th>
            <vs-th> Data </vs-th>
            <vs-th> Actiune </vs-th>
            <vs-th> Comentarii </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i + 123" v-for="(tr, i) in history" :data="tr">
            <vs-td>
              {{ tr.admin }}
            </vs-td>
            <vs-td>
              {{ tr.time }}
            </vs-td>
            <vs-td>
              {{ tr.action }}
            </vs-td>
            <vs-td>
              {{ tr.comments }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-dialog>
    <vs-dialog overflow-hidden prevent-close v-model="actions">
      <!-- {{btnT}} -->
      <template #header>
        <h4 class="not-margin">
          {{ btnT.titlu_popup }}
        </h4>
      </template>
      <vs-input
        placeholder="Pret achizitie (format 0.00)"
        v-model="valueSend"
        v-if="btnT.value_needed && btnT.titlu_popup != '3.AWB Furnizor'"
      />
      <vs-input
        placeholder="AWB furnizor"
        v-model="valueSend"
        v-if="btnT.value_needed && btnT.titlu_popup == '3.AWB Furnizor'"
      />
      <vs-input
        style="margin-top: 20px"
        placeholder="Link comanda furnizor"
        v-model="nrcomandafurnizor"
        v-if="btnT.fashion_value_needed"
      />
      <div v-if="btnT.type == 'genereazaawbclient'">
        <vs-select placeholder="Select" v-model="btnT.default_locatie" :key="btnT.locatii.length" >
          <vs-option :label="l.name" v-for="(l, index) in btnT.locatii" :value="l.value" :key="index">  
            {{l.name}}
          </vs-option>
        </vs-select>
              <vs-input
               label="Kg"
              style="margin-top:30px;"
        placeholder="Kg"
        v-model="btnT.default_kg"
        
      />
            <!-- <vs-input
             label="Nr. colete"
             style="margin-top:30px;"
        placeholder="Nr. colete"
        v-model="btnT.default_colete"
      /> -->

        <!-- {{btnT}} -->
      </div>
      <textarea
        v-model="comentariu"
        style="margin-top: 20px; width: 100%; padding: 10px; outline: none"
        placeholder="Comentariu"
      ></textarea>
                  <vs-button
                  v-if="btnT.type == 'genereazaawbclient'"
        style="margin-left: auto; margin-top: 20px"
        @click="orderProcessing(btnT.procesare_order_parameter)"
        warning
        border
      >
        <i :class="btnT.icon"></i> Salveaza
      </vs-button>
      <vs-button
        style="margin-left: auto; margin-top: 20px"
        @click="orderProcessing(btnT.procesare_order_parameter)"
        v-if="btnT.type == 'orderprocessing' && btnT.possible"
        warning
        border
      >
        <i :class="btnT.icon"></i> Salveaza
      </vs-button>
    </vs-dialog>
    <vs-dialog v-model="openUpload" @close="value7 = ''">
      <template #header>
        <h4 class="not-margin">Incarca factura {{ value7 }}</h4>
      </template>
      <VueCtkDateTimePicker
        :only-date="true"
        format="DD-MM-YYYY"
        v-model="value7"
      ></VueCtkDateTimePicker>
      <!-- <vs-input
        type="date"
        v-model="value7"
        label="Date"
         data-date="" data-date-format="DD MMMM YYYY"
      /> -->
      <vs-button
        style="margin-left: auto"
        square
        danger
        border
        @click="uploadBtn"
      >
        <i class="bx bx-upload"></i> Incarca factura
      </vs-button>
      <!-- <vs-button
            @click="uploadFile(1)"
      >
        Salveaza
      </vs-button> -->
    </vs-dialog>
    <input
      type="file"
      @change="uploadFile"
      style="width: 0; height: 0; position: absolute; top: -534500000px"
      id="upload"
      name="file"
      ref="upload"
    />
    <vs-dialog v-model="openUploadStorn" prevent-close @close="value7 = ''">
      <template #header>
        <h4 class="not-margin">Incarca factura {{ value7 }}</h4>
      </template>
      <VueCtkDateTimePicker
        :only-date="true"
        format="DD-MM-YYYY"
        v-model="value7"
      ></VueCtkDateTimePicker>
        <vs-input
        style="margin-top: 20px"
        placeholder="Valoare Storno"
        v-model="storn_value"
      />
      <!-- <vs-input
        type="date"
        v-model="value7"
        label="Date"
         data-date="" data-date-format="DD MMMM YYYY"
      /> -->
      <vs-button
        style="margin-left: auto"
        square
        danger
        border
        @click="uploadBtnStorn"
      >
        <i class="bx bx-upload"></i> Incarca storn
      </vs-button>
      <!-- <vs-button
            @click="uploadFile(1)"
      >
        Salveaza
      </vs-button> -->
    </vs-dialog>
    <input
      type="file"
      @change="uploadFileStorn"
      style="width: 0; height: 0; position: absolute; top: -534500000px"
      id="uploadstorn"
      name="file"
      ref="uploadstorn"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      btns: [],
      id_comanda: "",
      data_comanda: "",
      observatii: "",
      m_plata: "",
      data_maxima: "",
      facturare: [],
      livrare_la_domiciliu: [],
      c_type: "",
      status_comanda: [],
      small_buttons: [],
      listare_produse: [],
      history: null,
      historyOpened: false,
      actions: false,
      btnT: {},
      comentariu: "",
      valueSend: "",
      openUpload: false,
      value7: "",
      download_invoice: [],
      nrcomandafurnizor: "",
      openUploadStorn: false,
      storn_value:""
    };
  },
  methods: {
    sendData() {
      // alert(this.c_type)
      this.axios
        .get(
          `/orderactions?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${this.$route.params.id}&action=${this.c_type}&comments=${this.observatii}`
        )
        .then((response) => {
          response = response.data;
          console.log(response);
          // this.$router.push("/comenzi");
          location.reload();
          // this.loadPage()
        });
    },
    async orderProcessing(orderparameter) {
      // alert(orderparameter)
      // alert(value_needed)
      // alert(this.comentariu)
      // alert(this.nrcomandafurnizor)
      let awb = "";
      if(this.btnT.type == 'genereazaawbclient') {
        awb += "&awblocatie=" + this.btnT.default_locatie + "&awbkg=" + this.btnT.default_kg + "&awbcolete=" + this.btnT.default_colete;
      }
      const validare = await this.axios.get(
        `/validare00?value=${this.valueSend}`
      );
      if (
        this.btnT.validate_00 == false ||
        this.btnT.value_needed == false ||
        validare.data.valid == true
      ) {
        if (
          (this.btnT.value_needed && this.valueSend == "") ||
          (this.btnT.fashion_value_needed && this.nrcomandafurnizor == "")
        )
          alert("Completeaza toate campurile corect!");
        else {
          this.axios
            .get(
              `/orderprocessing?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${this.$route.params.id}&orderparameter=${orderparameter}&value=${this.valueSend}&comments=${this.comentariu}&nrcomandafurnizor=${this.nrcomandafurnizor}${awb}`
            )
            .then((response) => {
              response = response.data;
              console.log(response);
              location.reload();
              // this.loadPage()
            });
        }
      } else {
        alert("Format pret incorect!");
      }
    },
    uploadBtnStorn() {
      this.$refs.uploadstorn.click();
      console.log(this.$refs.upload);
    },
    async uploadFileStorn() {
      let pdf = this.$refs.uploadstorn.files[0];
      let fd = new FormData();
      fd.append("file", pdf);
      const validare = await this.axios.get(
        `/validare00?value=${this.storn_value}`
      ); 
      if (this.value7&&validare.data.valid) {
        this.axios
          .post(
            `/uploadstornofurnizor?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${this.$route.params.id}&time=${this.value7}&valuestorno=${this.storn_value}`,
            fd
          )
          .then((response) => {
            console.log(response);
            location.reload();
            // this.$store.commit("VALIDATE_TOKEN",response.validsession)
          });
      } else {
        alert("Completeaza toate campurile corect");
      }
    },
    uploadBtn() {
      this.$refs.upload.click();
      console.log(this.$refs.upload);
    },
    deleteProduct(id, comment) {
      this.axios
        .get(
          `/orderprocessing?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${this.$route.params.id}&orderparameter=6&deletevalue=${id}&comments=${comment}`
        )
        .then((response) => {
          response = response.data;
          console.log(response);
          location.reload();
        });
    },
    uploadFile() {
      let pdf = this.$refs.upload.files[0];
      let fd = new FormData();
      fd.append("file", pdf);
      if (this.value7) {
        this.axios
          .post(
            `/uploadfacturadistribuitor?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${this.$route.params.id}&time=${this.value7}`,
            fd
          )
          .then((response) => {
            console.log(response);
            location.reload();
            // this.$store.commit("VALIDATE_TOKEN",response.validsession)
          });
      } else {
        alert("Completeaza toate campurile");
      }
    },
    openHistory() {
      this.historyOpened = true;
      this.axios
        .get(
          `/historyorder?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&idcomanda=${this.$route.params.id}`
        )
        .then((response) => {
          response = response.data;
          this.history = response.orderhistory;
        });
    },
    loadPage() {
      this.axios
        .get(
          `/editarecomanda?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&idcomanda=${this.$route.params.id}`
        )
        .then((response) => {
          response = response.data;
          this.btns = response.upbuttons;
          this.id_comanda = response.comanda;
          this.data_comanda = response.data;
          this.data_maxima = response.detalii_comanda.observatii_client;
          this.m_plata = response.detalii_comanda.medota_de_plata;
          this.facturare = response.facturare;
          this.livrare_la_domiciliu = response.livrare_la_domiciliu;
          this.status_comanda = response.status_comanda;
          this.small_buttons = response.small_buttons;
          this.listare_produse = response.listare_produse;
          this.c_type = response.activestatus;
          this.download_invoice = response.download_invoice;
          console.log(response);
        });
    },
  },
  beforeMount() {
    this.loadPage();
  },
};
</script>

<style lang="scss" scoped>
.edit-comanda {
  padding: 1rem;
  &__top {
    display: flex;

    &-right {
      margin-left: auto;
      display: flex;
    }
  }

  &__main {
    padding: 0.5rem;
    box-shadow: 2px 2px 3px 0 rgb(128, 128, 128);
    // display: flex;
    // justify-content: space-between;
    margin-top: 50px;

    &-template {
      width: 30%;
      display: flex;
      flex-direction: column;
      &__title {
        display: block;
        padding-bottom: 5px;
        border-bottom: 1px solid gray;
      }

      &__list {
        margin-top: 5px;
        span,
        b {
          font-size: 0.8rem;
        }
      }
    }

    &-bottom {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgb(184, 184, 184);

      &-btns {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__table {
    margin-top: 20px;
  }
}
</style>