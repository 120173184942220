<template>
    <div style="padding-left:1rem;;overflow:hidden;" v-if="orders">
    <AppTitle :title="orders.pagetitle" style="padding:2rem 0;"/>
    <div>
        <vs-table >
        <template #thead>
          <vs-tr style="color:black;">
            <vs-th v-for="(t, index) in orders.titles" :key="index+123">
              {{t}}
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody style="width:500vh!important;">  
          <vs-tr
            :key="i"
            v-for="(tr, i) in orders.table"
            :data="tr"
            :style="tr.color" 
            class="work-logs__log"
          >
            <vs-td v-for="(v, index) in tr" :key="index">
            <div >
                 <span v-if="tr[index].show&&tr[index].type=='static'">{{tr[index].value}}</span> 
                <span v-if="tr[index].show&&tr[index].type=='html'" v-html="tr[index].value"></span> 
                <vs-button
                        @click="goLink(tr[index].redirectlink, tr[index].newtab)"
                        v-if="tr[index].show&&tr[index].type=='linkcuicon'"
                        icon
                        color="discord"
                    >
                    <i :class='tr[index].iconlink'></i>
                </vs-button>
                <div v-if="tr[index].show&&tr[index].type=='upload'">
                    <input type="file" @change="uploadBack(tr[index].uploadeorderid)" style="width:0;height:0;position:absolute;top:-534500000px;" :id="tr[index].uploadeorderid" :name="tr[index].uploadeorderid" :ref="tr[index].uploadeorderid">
                    <vs-button
                        @click="uploadFile(tr[index].uploadeorderid)"
                        icon
                        color="discord"
                    >
                    <i class='bx bx-upload' ></i>
                </vs-button>
                </div>
            <vs-button
            dark
            :style="tr[index].actioncolor"
            v-if="tr[index].show&&tr[index].type=='button_link'"
            @click="goLink(tr[index].redirectlink, tr[index].newtab)"
            >
                {{tr[index].value}}
            </vs-button>
            <vs-button
            :style="tr[index].actioncolor"
            v-if="tr[index].show&&tr[index].type=='action'"
            @click="openModal(tr[index])"
            >
                {{tr[index].value}}
            </vs-button>
            <vs-button
            dark
            icon
            v-if="tr[index].show&&tr[index].type=='produse'"
            @click="openModal(tr[index])"
            >
                 <i class='bx bxs-cart'></i>
            </vs-button>
            <div v-if="tr[index].show&&tr[index].type=='hover'" class="block_expand" style="cursor:pointer;">
                <span>{{tr[index].value}}</span>
                <span class="block_expand-hidden">{{tr[index].hiddenvalue}}</span>
            </div>
            <div v-if="tr[index].show&&tr[index].type=='image'" >
                <img :src="tr[index].imagesource" alt="delivery">
            </div>
            <div v-if="tr[index].show&&tr[index].type=='waiting'" class="block_expand" style="cursor:pointer;">
                <span><i class='bx bx-time' ></i></span>
                <span class="block_expand-hidden">{{tr[index].hoverwaiting}}</span>
            </div>
            <div v-if="tr[index].show&&tr[index].type=='staticok'" class="block_expand" style="cursor:pointer;">
                <span style="color:green;font-size:1.5rem!important;"><i class='bx bx-check' ></i></span>
                <span class="block_expand-hidden">{{tr[index].hoverok}}</span>
            </div>
            </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div v-if="fullscreen==false">
      <vs-dialog v-model="modal" @close="closeModal" >
          <vs-select
          style="margin-top:2rem;margin-bottom:20px;"
        label-placeholder="Motiv"
       v-model="dialog_select"
       @input="selectInput"
      >
        <vs-option :label="m" v-for="(m, index) in motive" :key="index" :value="m">
          {{m}}
        </vs-option>
        <vs-option label="Motiv propriu" value="999">
          Motiv propriu
        </vs-option>
      </vs-select>
          <textarea name="" v-if="showtext" v-model="order.reason" placeholder="Te rugam sa introduci motivul actiunii" id="" ></textarea>
            <vs-input
            
            v-if="order.showpret"
            style="margin-top:2rem;margin-bottom:1rem;"
                type="number"
                v-model="order.achizitie"
                label="Pret achizitie"

         
            > 
            <template #message-danger v-if="order.err">
                {{order.err}}
            </template>
        </vs-input>
  
        <vs-button style="margin-left:auto;" @click="sendAction"> 
            Send
        </vs-button>
      </vs-dialog>
    </div>
      <div v-if="fullscreen==true">
    <vs-dialog overflow-hidden full-screen v-model="modal"   @close="closeProds">
        <template #header>
          <h4 class="not-margin">
            Produse din comanda
          </h4>
        </template>
 <vs-table>
    <template #thead>
      <vs-tr>
        <vs-th>
          Name 
        </vs-th>
        <vs-th>
          Extern
        </vs-th>
        <vs-th>
          Cantitate 
        </vs-th>
        <vs-th>
          Sterge
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
      v-for="(c, index) in comanda.produse.listproduse"
      :key="index"
      >
        <vs-td>
            <a style="color:black;cursor:pointer" target="_blank" :href="c.name.link">{{c.name.name}}</a>
        </vs-td>
        <vs-td>
            <vs-button icon dark @click="goLink(c.source.link, true)">
                <i class='bx bxs-right-top-arrow-circle' ></i>
            </vs-button>
        </vs-td>
        <vs-td  >
            <vs-button v-if="c.updatequantity_button.show" icon dark @click="edit =c.updatequantity_button, editValue =c.updatequantity_button.quantity, editIndex = index, editActive = 'edit', copyEditValue = c.updatequantity_button.quantity">
                {{c.updatequantity_button.quantity}}
            </vs-button>
            <span v-else>{{c.updatequantity_button.quantity}}</span>
        </vs-td>
        <vs-td edit @click="editActive = 'remove', edit = c, editIndex = index">
            <vs-button icon danger v-if="c.removeproduct_button.show" >
                <i class='bx bxs-trash' ></i>
            </vs-button>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
  <div style="margin-left:auto;display:flex;">
      <span style="display:block;margin-left:auto;margin-right:50px;"><b>Total:</b>&nbsp;{{comanda.produse.totalproduseron}}</span>
  </div>
    </vs-dialog>
     <vs-dialog v-model="editActive" v-if="editActive" @close="closeEdit">
         <template #header v-if="editActive=='edit'">
            Actualizeaza cantitatea
        </template>
         <template #header v-if="editActive=='remove'">
            Sterge produse
        </template >
        <div v-if="editActive=='remove'">
      <vs-select placeholder="Motiv" v-model="removeReason">
        <vs-option
            v-for="(m, index) in comanda.produse.listproduse[0].motivepredefinitestergereprodus"
            :key="index"
            :label="m" :value="m">
                {{m}} 
        </vs-option>
        <vs-option label="Motiv propriu" value="999">
            Motiv propriu
        </vs-option>
      </vs-select> 
        <textarea v-if="removeReason==999"  v-model="removeReasontxt" placeholder="Te rugam sa introduci motivul actiunii" id="" ></textarea>
        <vs-button icon danger style="margin-left:auto;margin-top:10px;" @click="removeProduct">
            <i class='bx bxs-trash' ></i>
            Sterge
        </vs-button>      
        </div>
        
       <div v-if="editActive=='edit'">
            <vs-input
            style="margin-top:20px;"
            min="1"
            type="number"
            label="Cantitate"
            v-model="editValue"
            />
     <vs-select placeholder="Motiv" v-model="removeReason" style="margin-top:2rem;">
        <vs-option
            v-for="(m, index) in comanda.produse.listproduse[0].motivepredefinitestergereprodus"
            :key="index"
            :label="m" :value="m">
                {{m}} 
        </vs-option>
        <vs-option label="Motiv propriu" value="999">
            Motiv propriu
        </vs-option>
      </vs-select> 
        <textarea v-if="removeReason==999"  v-model="removeReasontxt" placeholder="Te rugam sa introduci motivul actiunii" id="" ></textarea>
        <vs-button icon style="margin-left:auto;margin-top:20px;" @click="updateProduct">
            <i class='bx bxs-trash' ></i>
            Actualizeaza
        </vs-button>    
       </div>
     </vs-dialog>
      </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            orders:null,
            modal:false,
            order:{
                actionparameter:"",
                actionorderid:"",
                reason:"",
                achizitie:"",
                showpret:false, 
                err:""
            },
            upload_id:"",
            dialog_select:0,
            showtext:false,
            motive:[],
            fullscreen:false,
            comanda:null,
            editActive:false,
            edit:null,
            editIndex:1,
            removeReason:"",
            removeReasontxt:"",
            copyEditValue:"",
            editValue:null,
            test:false
        }
    },
    methods:{
        openModal(z){
            console.log(z)
            this.modal=true;
            if(z.type == "produse") {
                console.log(z)
                this.comanda = z;
                this.fullscreen = true;
                
            }
            else {
                this.order.actionorderid = z.actionorderid;
                this.order.actionparameter = z.actionparameter;
                this.order.showpret = z.inputpretactizitie;
                this.motive = [...z.listmotive];
                console.log(this.order) 
            }
        },
        closeEdit(){
            if(this.editActive == 'edit') {
                this.editActive = null;
                this.edit = "";
            }
            this.loadPage();
            this.removeReason = ""
            this.removeReasontxt = "";
            this.edit = null;
        },
        closeProds(){
            this.modal = false;
            this.fullscreen = false;
        },
        selectInput(){
            if(this.dialog_select == '999') {
                this.showtext = true;
            }
            else {
                this.showtext = false;
                this.order.reason = ""
            }
        },
        closeModal(){
            this.modal = false;
            this.order.actionorderid = "";
            this.order.actionparameter = "";
            this.order.reason = '';
            this.order.showpret = false;
            this.order.achizitie = "";
            this.order.err = "";
            this.showtext = false;
            this.dialog_select = 0;
            this.comanda = null;
            this.fullscreen = false;
        },
        sendAction(){
            let reason = "";
            if(this.dialog_select==999){
                reason = this.order.reason;
            }
            else {
                reason = this.dialog_select;
            }
            if(this.order.showpret==false){
                this.axios.get(`/adminaction?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&actionparameter=${this.order.actionparameter}&actionorderid=${this.order.actionorderid}&reason=${reason}&pretachizitie=${this.order.achizitie}`).then((response) => {
                    console.log(response)
                    this.loadPage();
                    this.closeModal()       
                })
            }
            else {
                if(!this.order.achizitie==""){
                    this.axios.get(`/adminaction?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&actionparameter=${this.order.actionparameter}&actionorderid=${this.order.actionorderid}&reason=${reason}&pretachizitie=${this.order.achizitie}`).then((response) => {
                        console.log(response)
                        this.loadPage();
                        this.closeModal()       
                    })
                }
                else 
                this.order.err = "Pretul achizitiei este obligatoriu"
            }
        },
        uploadFile(id){
            console.log(id)
            console.log(this.$refs)
            this.upload_id = id;
            this.$refs[id][0].click();
        },
        updateProduct(){
 if(this.removeReason == '' || (this.removeReasontxt==''&&this.removeReason==999)){
                    this.$vs.notification({
                        progress: 'auto',
                        color:"danger",
                        position:"top-right",
                        title: 'Trebuie sa selectezi un motiv pentru care doresti sa faci aceasta actiune 😢',
                    })
            }
            else {
                let reason = "";
                if(this.removeReason!=999) {
                    reason = this.removeReason;
                }
                else {
                    reason = this.removeReasontxt;
                }
                console.log(this.edit)
                this.axios.post(`/removeorupdatequantity?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&actiontype=updatequanity&reason=${reason}&productid=${this.edit.productid}&orderid=${this.edit.orderid}&size=${this.edit.size}&userid=${this.edit.userid}&quantity=${this.edit.quantity}&newquantity=${this.editValue}`).then((response) => {
                    console.log(response) 
                    this.$vs.notification({
                        progress: 'auto',
                        color:"success",
                        position:"top-right",
                        title: 'Produsul a fost modificat cu succes 😄',
                    })
                    this.comanda.produse.listproduse[this.editIndex].updatequantity_button.quantity = this.editValue;
                    this.editIndex = null;
                    this.loadPage();
                    this.editActive = false;
                    this.closeEdit()
                   // this.$store.commit("VALIDATE_TOKEN",response.validsession)
                })
            }           
        },
        removeProduct(){
            if(this.removeReason == '' || (this.removeReasontxt==''&&this.removeReason==999)){
                    this.$vs.notification({
                        progress: 'auto',
                        color:"danger",
                        position:"top-right",
                        title: 'Trebuie sa selectezi un motiv pentru care doresti sa stergi produsul 😢',
                    })
            }
            else {
                let reason = "";
                if(this.removeReason!=999) {
                    reason = this.removeReason;
                }
                else {
                    reason = this.removeReasontxt;
                }
                // console.log(reason)
                // console.log(this.edit.removeproduct_button.quantity)
                // console.log(this.edit.removeproduct_button.productid)
                // console.log(this.edit.removeproduct_button.orderid)
                // console.log(this.edit.removeproduct_button.size)
                // console.log(this.edit.removeproduct_button.userid)
                this.axios.post(`/removeorupdatequantity?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&actiontype=removeproduct&reason=${reason}&productid=${this.edit.removeproduct_button.productid}&orderid=${this.edit.removeproduct_button.orderid}&size=${this.edit.removeproduct_button.size}&userid=${this.edit.removeproduct_button.userid}&quantity=${this.edit.removeproduct_button.quantity}`).then((response) => {
                    console.log(response) 
                    this.removeReasontxt = "";
                    this.removeReason = "";
                    this.$vs.notification({
                        progress: 'auto',
                        color:"success",
                        position:"top-right",
                        title: 'Produsul a fost sters cu succes 😄',
                    })
                    this.comanda.produse.listproduse.splice(this.editIndex, 1); 
                    this.editIndex = null;
                    this.loadPage();
                    this.editActive = false;
                    this.closeEdit()

                    // this.$store.commit("VALIDATE_TOKEN",response.validsession)
                })
            }
        },
        uploadBack(id){
                let pdf = this.$refs[id][0].files[0];
                let fd= new FormData()
                fd.append('file', pdf)
                this.axios.post(`/uploadinvoice?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&orderid=${id}`, fd).then((response) => {
                    console.log(response) 
                    this.loadPage();
                    this.$vs.notification({
                        progress: 'auto',
                        color:"success",
                        position:"top-right",
                        title: 'Fisier incarcat cu succes 😄',
                        text: `Factura a fost adaugata cu succes.`
                    }) 
                    // this.$store.commit("VALIDATE_TOKEN",response.validsession)
                })
        },
        goLink(link, newtab){
            if(!newtab)
                window.location = link;
            else 
                window.open(link, '_blank').focus();
        },
        loadPage(){
            this.axios.get(`/generallist?adminid=${this.$store.state.adminid}&admintoken=${this.$store.state.admintoken}&generalparameter=${this.$route.params.id}`).then((response) => {
                response = response.data;
                console.log(response) 
                this.orders = response;
                this.$store.commit("VALIDATE_TOKEN",response.validsession)
            })
        }
    },
   beforeMount(){
       this.loadPage();
   } 
}
</script>

<style lang="scss" scoped>
textarea {
    width: 100%;
    margin-top: 2rem;
    resize: none;
    outline: none;
    height: 200px;
    padding: 1rem;
    font-family: $light-main;
    border: 1px solid rgb(202, 202, 202);
    border-radius: 10px;
}
.block_expand {
    overflow: hidden;
    transition: .6s;
    padding: 1rem 0;
}

.block_expand-hidden {
    display: none;
}

.block_expand:hover > .block_expand-hidden {
    display: block!important;
}
</style>